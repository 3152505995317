import { Grid, Typography } from '@mui/material';
import { AnnotationAssignment } from 'interfaces/annotation';
import React from 'react';
import AnnotationAssignmentTable from './AnnotationAssignmentTable';

interface AnnotationAssignmentViewProps {
  annotationAssignment: AnnotationAssignment;
  studyId: string;
}

const AnnotationAssignmentView: React.FC<AnnotationAssignmentViewProps> = ({ annotationAssignment, studyId }) => {
  return (
    <Grid item container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="h4">{annotationAssignment.name}</Typography>
      </Grid>
      <Grid item>
        <AnnotationAssignmentTable studyId={studyId} annotationAssignment={annotationAssignment} />
      </Grid>
    </Grid>
  );
};

export default AnnotationAssignmentView;
