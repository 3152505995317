import { useQuery } from '@tanstack/react-query';
import { find, findIndex, isEmpty, map, some } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { getSlideById, getSlideByIdKeys } from 'api/slides';
import { getStudyProcedure, getStudyProcedureQueryKey } from 'api/study';
import { RouteProps } from 'components/Auth/PrivateRoute';
import { DEFAULT_PAGE_SIZE } from 'components/StudyDashboard/ProceduresPage/ProcedurePagination';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useCurrentStateCases } from 'utils/useCurrentStateCases';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { CaseView } from './CaseView';
import NotFoundMessage from './NotFoundMessage';
import { useFilteredSlidesViewerNavigationPrefetchData } from './useFilteredCaseIds';
import { usePrefetchedCaseData } from './usePrefetchedCaseData';

export const SlidePage: React.FunctionComponent<React.PropsWithChildren<RouteProps>> = (props) => {
  const params = useParams();
  const slideId: string = params.slideId;

  const { hasPermission } = usePermissions();
  const canViewPendingSlides: boolean = hasPermission(Permission.ViewPendingSlides);
  const canAccessAllLabs = hasPermission(Permission.ApplyPermissionsAcrossLabs);

  const [caseId, setCaseId] = useQueryParam('caseId', NumberParam);
  const [pendingSlidesMode, setPendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const [slidesMode, setSlidesMode] = useQueryParam('slidesMode', BooleanParam);

  // '!caseId' is for scenarios that caseId is null (isNaN(null) is false)
  const isMissingCase = !caseId || isNaN(caseId);
  const { queryParams, encodedFilters, setQueryParams, generateEncodedParams } = useEncodedFilters();
  const [fromStudyFilter, setFromStudyFilter] = useQueryParam('fromStudyFilter', StringParam);

  const { procedures, isLoading: isCasesLoading, allMockCasesOfPendingSlides } = useCurrentStateCases();

  const currentCase = find(
    procedures,
    (procedure) => (isMissingCase || procedure.id === caseId) && some(procedure.slides, { id: slideId })
  );

  const studyId = currentCase?.studyId || params?.studyId || queryParams.filters?.studyId || fromStudyFilter;

  const { isLoading: isPrefetchedLoading, data: allSlidePrefetchedData } =
    useFilteredSlidesViewerNavigationPrefetchData({
      studyId,
      enabled: Boolean(studyId) && Boolean(slideId),
      focusedSlideId: slideId,
    });

  // This is to sync the caseId with the slideId if no caseId param is provided
  const slidePrefetchedData = useMemo(
    () => find(allSlidePrefetchedData, { slideId }),
    [allSlidePrefetchedData, slideId]
  );
  const caseIdOfSlide = slidePrefetchedData?.caseId
    ? slidePrefetchedData?.caseId
    : !pendingSlidesMode
    ? currentCase?.id
    : undefined;

  const {
    data: studyCaseData,
    error: caseError,
    isLoading: isStudyCaseLoading,
    isError: isCaseError,
    isPlaceholderData: isCasePlaceholderData,
    refetch: refetchCaseData,
  } = useQuery(
    getStudyProcedureQueryKey(studyId, caseId, queryParams),
    ({ signal }) => getStudyProcedure(studyId, caseId, encodedFilters, signal),
    {
      placeholderData: currentCase ? { procedure: currentCase } : undefined,
      enabled: !isMissingCase,
    }
  );

  // Sync params with real state
  useEffect(() => {
    if (!canViewPendingSlides && pendingSlidesMode) {
      setPendingSlidesMode(false, 'replaceIn');
    }

    if (caseIdOfSlide) {
      // It means that this slide has a case and it is not pending
      if (isMissingCase) {
        setCaseId(caseIdOfSlide, 'replaceIn');
      }
      if (pendingSlidesMode) {
        setPendingSlidesMode(false, 'replaceIn');
      }
    } else if ((!isStudyCaseLoading || isMissingCase) && !isPrefetchedLoading && !isCasesLoading) {
      if (!isMissingCase) {
        setCaseId(undefined, 'replaceIn');
      }
      if (!pendingSlidesMode && canViewPendingSlides) {
        setPendingSlidesMode(true, 'replaceIn');
      } else if (pendingSlidesMode && !slidePrefetchedData) {
        setPendingSlidesMode(false, 'replaceIn');
      }
    }

    // Must be on slidesMode when on slide page
    if (!slidesMode) {
      setSlidesMode(true, 'replaceIn');
    }
  }, [slidesMode, caseIdOfSlide, isMissingCase, pendingSlidesMode, isPrefetchedLoading, canViewPendingSlides]);

  const page: number = queryParams.page || 1;
  const pageSize: number = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  const caseIndex = findIndex(allMockCasesOfPendingSlides, (mockCase) => some(mockCase.slides, { id: slideId }));
  // Sync pending slide pagination in case we're asking to view a pending slide that's not in the current page
  const pendingSlidePage = pendingSlidesMode && caseIndex >= 0 ? Math.floor(caseIndex / pageSize) + 1 : page;

  React.useEffect(() => {
    if (pendingSlidesMode && page !== pendingSlidePage) {
      setQueryParams({ page: pendingSlidePage });
    }
  }, [pendingSlidesMode, page, pendingSlidePage]);

  const fullSlideQueryEncodedFilters = generateEncodedParams({
    filters: { ...(queryParams?.filters || {}), studyId },
  });

  const {
    data: fullSlideData,
    isLoading: isFullSlideLoading,
    isError: isFullSlideError,
    error: fullSlideError,
    refetch: refetchPendingSlideData,
  } = useQuery(
    getSlideByIdKeys({
      slideId,
      encodedFilters: fullSlideQueryEncodedFilters,
    }),
    ({ signal }) =>
      getSlideById({
        slideId,
        encodedFilters: fullSlideQueryEncodedFilters,
        signal,
      }),
    {
      placeholderData: find(currentCase?.slides, { id: slideId }),
      // We only need to fetch the slide data if the slide isn't assigned to a case or if case is missing
      enabled: Boolean(slideId) && (isMissingCase || (!studyCaseData?.procedure && !isStudyCaseLoading)),
    }
  );

  const isDataLoading =
    isPrefetchedLoading || (isMissingCase ? isCasesLoading : isStudyCaseLoading || isFullSlideLoading);

  const { labId, setLabId } = useCurrentLabId();

  React.useEffect(() => {
    const actualStudyId = studyCaseData?.procedure?.studyId || studyId;
    if (actualStudyId && fromStudyFilter !== actualStudyId) {
      setFromStudyFilter(actualStudyId, 'replaceIn');
    }
  }, [studyCaseData?.procedure?.studyId, studyId, fromStudyFilter, setFromStudyFilter]);

  const { caseData: prefetchedCaseData } = usePrefetchedCaseData({
    caseId,
    focusedSlideId: slideId,
    studyId: studyCaseData?.procedure?.studyId || studyId,
  });

  const caseData: Procedure = useMemo(() => {
    const isPrefetchData = Boolean(isCasePlaceholderData || (!studyCaseData?.procedure && prefetchedCaseData));
    const baseCaseData = studyCaseData?.procedure || prefetchedCaseData;
    return {
      ...baseCaseData,
      studyId: baseCaseData?.studyId || studyId,
      slides: isPrefetchData
        ? map(baseCaseData?.slides, (slide) => (slide.id === slideId ? fullSlideData || slide : slide))
        : baseCaseData?.slides,
    };
  }, [
    studyCaseData?.procedure,
    isCasePlaceholderData,
    fullSlideData,
    prefetchedCaseData,
    slideId,
    currentCase,
    labId,
    studyId,
  ]);

  const isError = isMissingCase ? isFullSlideError : isCaseError;
  const isPlaceholderData = isCasePlaceholderData || !studyCaseData?.procedure;

  const placeholderWithoutSlides =
    !isError && (isStudyCaseLoading || isFullSlideLoading) && isPlaceholderData && isEmpty(caseData?.slides);

  const refetch = React.useCallback(() => {
    refetchCaseData?.();
    refetchPendingSlideData?.();
  }, [refetchPendingSlideData, refetchCaseData]);

  const loadingFailed = isError || !caseData || isEmpty(caseData?.slides);

  const shouldChangeLab =
    canAccessAllLabs && !isDataLoading && loadingFailed && fullSlideData?.labId && fullSlideData?.labId !== labId;
  React.useEffect(() => {
    if (shouldChangeLab && fullSlideData?.labId) {
      setLabId(fullSlideData?.labId);
    }
  }, [shouldChangeLab, fullSlideData?.labId]);

  const loadingFinished = (!isDataLoading || caseData) && !placeholderWithoutSlides && !shouldChangeLab;

  if (loadingFinished && loadingFailed && isError) {
    console.error('Error loading slide page', {
      caseError,
      fullSlideError,
      slideId,
      studyId: studyCaseData?.procedure?.studyId || studyId,
      caseId,
      labId,
    });
  }

  return loadingFinished && (!caseData || isEmpty(caseData?.slides)) ? (
    <NotFoundMessage />
  ) : (
    <CaseView
      procedure={caseData}
      refetchProcedure={refetch}
      isAccessionViewer={false}
      isPlaceholderData={!isMissingCase && isPlaceholderData}
      isLoadingCaseData={!loadingFinished}
      {...props}
    />
  );
};
