import { compact, concat, Dictionary, flatten, isEmpty, isEqual, keyBy, map, values } from 'lodash';

import { Slide } from 'interfaces/slide';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { ChannelMetadata, channelsMetadataFromSlideChannels } from './channelMetadata';
import {
  FeatureMetadata,
  getAllFlatMapDeepHeatmaps,
  ParsedResults,
  parseSlideExperimentResults,
} from './featureMetadata';

export interface SlideChannelsAndResults {
  id: string;
  heatmapResults: {
    publishedResults: FeatureMetadata[];
    internalResults: { [key: string]: FeatureMetadata[] };
  };
  featuresResults: {
    publishedResults: FeatureMetadata[];
    internalResults: { [key: string]: FeatureMetadata[] };
  };
  internalHeatmaps: { [key: string]: FeatureMetadata[] };
  channelsMetadata: ChannelMetadata[];
  studyId: string;
}

export interface SlideWithChannelAndResults extends Slide, Partial<Omit<SlideChannelsAndResults, 'id'>> {
  viewerIndex: number;
}

export const isSlideLoadingChannelsOrResults = (slide: SlideWithChannelAndResults): boolean =>
  // Slide has experiment results but no heatmaps or features
  (!isEmpty(slide?.experimentResults) &&
    isEmpty(slide?.internalHeatmaps) &&
    isEmpty(slide?.heatmapResults?.internalResults) &&
    isEmpty(slide?.heatmapResults?.publishedResults) &&
    isEmpty(slide?.featuresResults?.internalResults) &&
    isEmpty(slide?.featuresResults?.publishedResults)) ||
  // Slide has no channels metadata but has channel marker types or channels
  (isEmpty(slide?.channelsMetadata) && (!isEmpty(slide?.channelMarkerTypes) || !isEmpty(slide?.channels)));

export const getSlidesChannelsAndResults = (
  slides: Slide[],
  studyId: string,
  formatBracketsOptions: FormatBracketsOptions
): Dictionary<SlideChannelsAndResults> => {
  return keyBy(
    map(slides, (slide) => {
      const emptyParsedResults: ParsedResults = {
        heatmaps: {
          publishedResults: [],
          internalResults: {},
        },
        features: {
          publishedResults: [],
          internalResults: {},
        },
      };
      const { channels, channelMarkerTypes, id, labId, experimentResults } = slide;
      const results = !formatBracketsOptions?.isLoading
        ? parseSlideExperimentResults(experimentResults || [], formatBracketsOptions)
        : emptyParsedResults;

      const channelsMetadata = channelsMetadataFromSlideChannels({
        channels,
        channelMarkerTypes,
        id,
        labId,
      });

      if (!isEmpty(experimentResults) && isEqual(results, emptyParsedResults) && !formatBracketsOptions?.isLoading) {
        console.warn(`Failed to parse results for slide ${id}`, { experimentResults, formatBracketsOptions });
      }

      return {
        id,
        heatmapResults: results.heatmaps,
        internalHeatmaps: results.internalHeatmaps,
        featuresResults: results.features,
        channelsMetadata,
        studyId,
      };
    }),
    'id'
  );
};

export const getAllFlatMapDeepHeatmapsFromSlide = (
  slide: Pick<SlideWithChannelAndResults, 'heatmapResults' | 'internalHeatmaps'>
): FeatureMetadata[] => {
  const baseLayerVisualizationSettings = compact(
    concat(
      slide?.heatmapResults?.publishedResults,
      flatten(values(slide?.heatmapResults?.internalResults)),
      flatten(values(slide?.internalHeatmaps))
    )
  );
  return getAllFlatMapDeepHeatmaps(baseLayerVisualizationSettings);
};
