import { useSignals } from '@preact/signals-react/runtime';
import { compact, filter, fromPairs, keys, map, omit } from 'lodash';
import { useMemo } from 'react';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  SlideWithChannelAndResults,
  getAllFlatMapDeepHeatmapsFromSlide,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { useProtomapTilesList } from 'utils/useProtomapTiles';
import { deckGLPMTLayer } from './deckGLPMTLayer';

export const usePmtLayers = ({
  slide,
  maxLevel,
  idPrefix,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  maxLevel: number;
  idPrefix: string;
  rescaleFactor?: number;
}) => {
  useSignals();
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[slide.viewerIndex];

  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings.value?.[slide.id];

  const pmtHeatmaps = filter(
    map(getAllFlatMapDeepHeatmapsFromSlide(slide), (heatmap) => ({
      slideInfo: omit(slide, 'viewerIndex'),
      ...heatmap,
    })),
    ({ heatmapUrl, heatmapType }) => Boolean(heatmapUrl) && heatmapType === 'pmt'
  );

  const { pmtLayersData } = useProtomapTilesList(pmtHeatmaps);
  const pmtVisualSettingsByHeatmapId = fromPairs(
    map(pmtLayersData, ({ pmtHeatmap }) => [
      pmtHeatmap?.id,
      fromPairs(
        map(
          filter(keys(slideLayerVisualizationSettings), (key) => pmtHeatmap?.id && key.startsWith(pmtHeatmap?.id)),
          (key) => [key, slideLayerVisualizationSettings[key].value]
        )
      ),
    ])
  );

  const pmtLayers = useMemo(
    () =>
      compact(
        map(pmtLayersData, ({ pmtHeatmap, pmtMetadata, pmtTileSource }) =>
          deckGLPMTLayer({
            idPrefix: `${idPrefix}-${slide.id}`,
            visualSettings: pmtVisualSettingsByHeatmapId[pmtHeatmap.id],
            pmtHeatmapId: pmtHeatmap.id,
            pmtMetadata,
            pmtTileSource,
            maxLevel,
            rescaleFactor,
          })
        )
      ),
    [slide.id, pmtLayersData, JSON.stringify(pmtVisualSettingsByHeatmapId), maxLevel, rescaleFactor]
  );

  return pmtLayers;
};
