import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useSignals } from '@preact/signals-react/runtime';
import { defaultLayerColors } from 'components/theme/theme';
import { sortBy } from 'lodash';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

export const ColorPicker: React.FC<
  React.PropsWithChildren<{
    currentColor: any;
    onColorChange: (color: string) => void;
    disable?: boolean;
    disableEditing?: boolean; // Still shows the color picker but disables editing
  }>
> = ({ currentColor, onColorChange, disable = false, disableEditing = false }) => {
  useSignals();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openColorPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeColorPopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        role="color-picker"
        size="small"
        sx={{
          minWidth: 17,
          height: 17,
          mr: 1,
          display: 'flex',
          ...(!disable ? { background: currentColor?.hex || currentColor } : {}),
          ...(disableEditing && !disable // If we only disable editing, we want to show the color but not allow editing
            ? {
                '&.Mui-disabled': {
                  background: currentColor?.hex || currentColor,
                },
              }
            : {}),
        }}
        variant="contained"
        onClick={openColorPopOver}
        disabled={disable || disableEditing}
      />
      <Popover
        id={id}
        open={open && !disable && !disableEditing}
        anchorEl={anchorEl}
        onClose={closeColorPopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <SketchPicker
          color={currentColor}
          onChange={(color) => {
            onColorChange(color?.hex);
          }}
          presetColors={sortBy(defaultLayerColors)}
        />
      </Popover>
    </>
  );
};
