import { PMTilesMetadata, PMTilesSource } from '@loaders.gl/pmtiles';
import { useQueries, useQuery } from '@tanstack/react-query';
import { compact, map, some, uniqBy } from 'lodash';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import { useMemo } from 'react';

export interface PMTilesResult {
  pmtHeatmap?: FeatureMetadata;
  pmtTileSource: PMTilesSource | null;
  pmtMetadata: PMTilesMetadata | null;
}

const nullResponse: PMTilesResult = { pmtTileSource: null, pmtMetadata: null };

export const setupProtomapTiles = async (pmtHeatmap: FeatureMetadata): Promise<PMTilesResult> => {
  const pmtTileSource = new PMTilesSource({ url: pmtHeatmap.heatmapUrl });
  const pmtMetadata = await pmtTileSource.metadata;
  return { pmtHeatmap, pmtTileSource, pmtMetadata };
};

export const useProtomapTiles = (pmtHeatmap: FeatureMetadata): PMTilesResult => {
  const { data } = useQuery(['pmtiles', pmtHeatmap.heatmapUrl], async () => setupProtomapTiles(pmtHeatmap));

  return data || nullResponse;
};

export const useProtomapTilesList = (pmtHeatmaps: FeatureMetadata[]) => {
  const pmtTilesQueries = useQueries({
    queries: map(
      uniqBy(pmtHeatmaps, (pmtHeatmap) => pmtHeatmap?.heatmapUrl),
      (pmtHeatmap) => ({
        queryKey: ['pmtiles', pmtHeatmap?.heatmapUrl],
        queryFn: async () => setupProtomapTiles(pmtHeatmap),
        enabled:
          Boolean(pmtHeatmap?.heatmapUrl) &&
          pmtHeatmap.heatmapType === 'pmt' &&
          // Wait for pre-signed URLs to be ready
          !pmtHeatmap.heatmapUrl.startsWith('s3://'),
      })
    ),
  });

  const loadingStates = map(pmtTilesQueries, 'isLoading');

  const pmtLayersData = useMemo(
    () => uniqBy(compact(map(pmtTilesQueries, 'data')), 'pmtHeatmap.id'),
    [JSON.stringify(loadingStates)]
  );

  return {
    pmtTilesQueries,
    pmtLayersData,
    isLoading: some(loadingStates),
  };
};
