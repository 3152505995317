import { load } from '@loaders.gl/core';
import { ImageLoader } from '@loaders.gl/images';
import GL from '@luma.gl/constants';
import { signal } from '@preact/signals-react';
import { DecodedPng, decode as decodePng } from 'fast-png';
import { Dictionary, compact, isEmpty, join, map, times } from 'lodash';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { MultiScaleImageData } from './utils';

export const multiScaleLayerLoadingStates = times(MAX_VIEWERS, () => signal<Dictionary<boolean>>({}));

export async function getImageDataFromUrl(
  url: string | null,
  isPng: boolean
): Promise<(ImageData | DecodedPng) | null> {
  try {
    if (!url) {
      return null;
    }
    if (isPng) {
      if (!url.endsWith('.png')) {
        console.warn('PNG requested but not provided', { url });
      }
      const fetchResponse = await fetch(url);
      if (!fetchResponse.ok) {
        return null;
      }
      const data = decodePng(await fetchResponse.arrayBuffer());
      return data;
    }
    const imageData = load(url, ImageLoader, {
      image: { type: 'data' },
      fetch: { cache: 'force-cache' },
      nothrow: true,
    });
    return await imageData;
  } catch (err) {
    if (
      err instanceof ProgressEvent ||
      err.message?.startsWith?.('Failed to fetch') ||
      err.message?.startsWith('The user aborted a request')
    ) {
      return null;
    } else {
      console.error("Couldn't load tile", url, err);
      return null;
    }
  }
}

const generateEmptyResponse = (selectionUrls: Array<string | null>, isPng: boolean): MultiScaleImageData => ({
  data: times(selectionUrls?.length ?? 0, () => null),
  format: undefined,
  dataFormat: undefined,
  isPng,
});

export async function getTileDataFromUrls(
  selectionUrls: Array<string | null>,
  isRgb: boolean,
  isPng: boolean,
  viewerIndex: number
): Promise<MultiScaleImageData> {
  // Early return if no selections
  if (isEmpty(selectionUrls)) {
    console.warn('No selections provided to getTileDataFromUrls');
    return null;
  }

  try {
    if (!multiScaleLayerLoadingStates[viewerIndex]) {
      console.warn('No loading states for viewer', viewerIndex);
      return null;
    } else {
      multiScaleLayerLoadingStates[viewerIndex].value = {
        ...multiScaleLayerLoadingStates[viewerIndex].value,
        [join(selectionUrls, ',')]: true,
      };
    }
    const tiles = await Promise.all(map(selectionUrls, (url) => getImageDataFromUrl(url, isPng)));

    if (!multiScaleLayerLoadingStates[viewerIndex]) {
      console.warn('No loading states for viewer', viewerIndex);
      return null;
    } else {
      multiScaleLayerLoadingStates[viewerIndex].value = {
        ...multiScaleLayerLoadingStates[viewerIndex].value,
        [join(selectionUrls, ',')]: false,
      };
    }

    if (isEmpty(compact(tiles))) {
      return null;
    }

    const tile = {
      data: tiles,
      format: isRgb ? GL.RGB : undefined,
      dataFormat: isRgb ? GL.RGB : undefined,
      isPng,
    };

    return tile;
  } catch (err) {
    console.error('Error fetching tile', err);

    return generateEmptyResponse(selectionUrls, isPng);
  }
}

export default getTileDataFromUrls;
