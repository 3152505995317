import { Main, overviewDrawerWidth } from 'components/atoms/FlexContainers/FlexContainers';
import { Permission } from 'interfaces/permissionOption';
import { find } from 'lodash';
import React, { useCallback } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import ProceduresPage from './ProceduresPage';

import UploadSlidesModal from './UploadSlidesModal/UploadSlidesModal';

import { Grid } from '@mui/material';
import { useTableEditingContext } from 'components/atoms/EditableDataGrid/TableEditingContext';
import EditSnackbar from 'components/EditSlidesAndCasesDisplay/EditSnackbar';
import { Study } from 'interfaces/study';
import { useCasesViewMode } from './ProceduresPage/useCasesViewMode';

interface Props {
  studies: Study[];
}

const MainContent = ({ studies }: Props) => {
  const { hasPermission } = usePermissions();
  const { bulkEditMode } = useTableEditingContext();

  const { queryParams } = useEncodedFilters();
  const currentStudy = find(studies, { id: queryParams.filters?.studyId });

  const [isUploadSlidesModalOpen, setIsUploadSlidesModalOpen] = useQueryParam('isUploadSlidesModalOpen', BooleanParam);

  const displayProcedureModeToggle = hasPermission(Permission.ViewCasesTable);

  const [advancedSearchDrawerOpen] = useQueryParam('advancedSearchDrawerOpen', BooleanParam);
  const [casesOverviewDrawerOpen] = useQueryParam('casesOverviewDrawerOpen', BooleanParam);
  const { casesViewMode } = useCasesViewMode();

  const onUploadSlidesModalClose = useCallback(() => {
    setIsUploadSlidesModalOpen(false);
  }, [setIsUploadSlidesModalOpen]);

  return (
    <Main
      leftDrawerOpen={advancedSearchDrawerOpen}
      rightDrawerOpen={casesOverviewDrawerOpen || (bulkEditMode && casesViewMode !== 'table')}
      rightDrawerWidth={overviewDrawerWidth}
    >
      <EditSnackbar />

      <Grid
        container
        sx={{
          display: 'block',
          mt: 1,
        }}
      >
        <UploadSlidesModal
          isOpen={isUploadSlidesModalOpen}
          studyId={queryParams.filters?.studyId}
          onClose={onUploadSlidesModalClose}
        />
        <ProceduresPage displayProcedureModeToggle={displayProcedureModeToggle} study={currentStudy} />
      </Grid>
    </Main>
  );
};

export default MainContent;
