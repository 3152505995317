import { DeckGLProps } from '@deck.gl/react/typed';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { isEmpty } from 'lodash';
import React from 'react';

import { Chip, Grid, useTheme } from '@mui/material';
import Loader from 'components/Loader';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { SlideDeckGLOverview } from './SlideDeckGLOverview';
import { SlideDeckGLViewer } from './SlideDeckGLViewer';

export const ViewerContainer: React.FC<{
  displaySlideId?: boolean;
  showNavigation?: boolean;
  slide: SlideWithChannelAndResults;
  baseImagePyramids: ImagePyramid;
  heatmapsImagePyramids: HeatmapsImagePyramids;
  viewSize: { width: number; height: number };
  onViewStateChange: DeckGLProps['onViewStateChange'];
  hideComments?: boolean;
  procedureId: number;
  isLoading?: boolean;
  showTaggedChip?: boolean;
}> = (props) => {
  const theme = useTheme();
  const darkBackground = !isEmpty(props.slide?.channelsMetadata);
  const [hideDeckGLOverlays] = useQueryParam('hideDeckGLOverlays', BooleanParam);
  return (
    <Grid
      item
      className="deckgl-slides-viewer"
      height="100%"
      width={props.viewSize.width}
      overflow="hidden"
      position="relative"
      flex={1}
      sx={{
        background: darkBackground ? 'black' : '#fff',
        ...(props.showTaggedChip
          ? {
              borderColor: theme.palette.mode === 'dark' ? 'primary.main' : 'secondary.main',
              borderWidth: 1,
              borderStyle: 'solid',
            }
          : {}),
      }}
      alignContent="center"
      justifyContent="center"
      onContextMenu={(event) => event.preventDefault()}
    >
      {!props.isLoading && props.baseImagePyramids ? (
        <>
          <SlideDeckGLViewer {...props} />
          {!hideDeckGLOverlays && Boolean(props.showNavigation) && (
            <SlideDeckGLOverview {...props} darkBackground={darkBackground} />
          )}
        </>
      ) : (
        <Loader />
      )}
      {!hideDeckGLOverlays && props.showTaggedChip && (
        <Chip
          label="Tagged Slide"
          variant="filled"
          size="small"
          sx={{
            zIndex: 100,
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: 0,
            color: 'black',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            border: '1px solid',
          }}
          icon={<LocalOfferIcon color="inherit" />}
        />
      )}
    </Grid>
  );
};
