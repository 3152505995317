export interface StainType {
  id: string;
  displayName: string;
  index: number;
  canBeMifMarker: boolean;
  aliases?: string[];
  deletedAt?: string | null;
}

export const MULTIPLEX_STAIN_ID = 'mplex';
