import { Grid, Typography, useTheme } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { filter, first, isEmpty, isString, map, partialRight, replace } from 'lodash';
import React from 'react';
import { CaseSearchItem } from 'services/searchIndex';

const matchWithOptions = partialRight(match, {
  findAllOccurrences: true,
  insideWords: true,
});

const MatchingText: React.FunctionComponent<
  React.PropsWithChildren<{
    propertyName: string;
    propertyValue: string | string[];
    searchQueryWithoutCommas: string;
  }>
> = ({ propertyName, propertyValue, searchQueryWithoutCommas }) => {
  let propertyValueArray: [number, number][][] = [];
  if (isString(propertyValue)) {
    propertyValueArray = [matchWithOptions(propertyValue, searchQueryWithoutCommas)];
    propertyValue = [propertyValue];
  } else {
    propertyValueArray = map(propertyValue, (value) => matchWithOptions(value, searchQueryWithoutCommas));
  }

  const theme = useTheme();

  return (
    <Grid container direction="row">
      <Grid item>
        <Typography
          sx={{
            color: 'text.secondary',
            marginRight: theme.spacing(1),
          }}
          display="inline"
        >
          Matching {propertyName}:
        </Typography>
      </Grid>
      <Grid item xs container>
        {map(propertyValueArray, (propertyValueMatches, propertyIndex) => (
          <Grid item key={`${propertyName}-${propertyIndex}`}>
            {map(parse(propertyValue[propertyIndex], propertyValueMatches), (part, index) => {
              const { highlight, text } = part;
              const key = `${index}-${text}`;
              return highlight ? (
                <Typography display="inline" fontWeight={700} key={key}>
                  {text}
                </Typography>
              ) : (
                <Typography display="inline" key={key}>
                  {text}
                </Typography>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

interface SearchResultMatchProps {
  option: CaseSearchItem;
  searchQuery: string;
}

const SearchResultMatch: React.FunctionComponent<React.PropsWithChildren<SearchResultMatchProps>> = ({
  option,
  searchQuery,
}) => {
  if (!searchQuery) {
    return null;
  }
  const searchQueryWithoutCommas = replace(searchQuery, /,/g, ' ');

  const matchingSlidesById = filter(option?.slides, (slide) => {
    const slideMatches = matchWithOptions(slide?.id, searchQueryWithoutCommas);
    return !isEmpty(slideMatches);
  });

  if (!isEmpty(matchingSlidesById)) {
    return (
      <MatchingText
        propertyName="slide ID"
        propertyValue={first(matchingSlidesById)?.id}
        searchQueryWithoutCommas={searchQueryWithoutCommas}
      />
    );
  }

  const matchingSlidesByFileName = filter(option?.slides, (slide) => {
    const slideMatches = matchWithOptions(slide?.fileName, searchQueryWithoutCommas);
    return !isEmpty(slideMatches);
  });

  if (!isEmpty(matchingSlidesByFileName)) {
    return (
      <MatchingText
        propertyName="file name"
        propertyValue={first(matchingSlidesByFileName)?.fileName}
        searchQueryWithoutCommas={searchQueryWithoutCommas}
      />
    );
  }

  const labelMatches = matchWithOptions(option?.caseLabel, searchQueryWithoutCommas);
  if (!isEmpty(labelMatches)) {
    return (
      <MatchingText
        propertyName="label"
        propertyValue={option?.caseLabel}
        searchQueryWithoutCommas={searchQueryWithoutCommas}
      />
    );
  }

  const matchingStudyName = matchWithOptions(option?.studyName, searchQueryWithoutCommas);
  if (!isEmpty(matchingStudyName)) {
    return (
      <MatchingText
        propertyName="study name"
        propertyValue={option?.studyName}
        searchQueryWithoutCommas={searchQueryWithoutCommas}
      />
    );
  }

  const numSlides = option?.slides?.length ?? 0;

  return <>{`${numSlides} slide${numSlides === 1 ? '' : 's'}`}</>;
};

export default SearchResultMatch;
