import { first, join, map, replace, uniq } from 'lodash';

import {
  Labels,
  PhotometricInterpretation,
  PixelSource,
  PixelSourceMeta,
  PixelSourceSelection,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/types';
import { DziChannel } from './utils';

const urlPrefix = /^https?:\/\/[^./]+(?:\.[^./]+)+\//;

export class OSDPixelSource<ChannelSource extends DziChannel = DziChannel> implements PixelSource<['layerIndex']> {
  _layerSources: ChannelSource[];

  public labels: Labels<['layerIndex']> = ['layerIndex', 'z', 'y', 'x'];

  public shape: number[];

  public meta: PixelSourceMeta;

  public maxLevel: number = 0;

  public minLevel: number = 0;

  _debug: boolean = false;

  constructor(
    osdChannelSources: ChannelSource[],
    {
      photometricInterpretation,
      minLevel = 0,
      maxLevel,
      debug = false,
    }: {
      photometricInterpretation?: PhotometricInterpretation;
      maxLevel: number;
      minLevel?: number;
      debug?: boolean;
    }
  ) {
    this._debug = debug;
    this.meta = {
      photometricInterpretation: photometricInterpretation
        ? photometricInterpretation
        : first(osdChannelSources).isRgb
        ? PhotometricInterpretation.RGB
        : PhotometricInterpretation.BlackIsZero,
      isPng: first(osdChannelSources).metadata.fileFormat === 'png',
    };
    this._layerSources = osdChannelSources;

    this.maxLevel = maxLevel;
    this.minLevel = minLevel;

    if (
      uniq(map(this._layerSources, 'dimensions.y')).length > 1 ||
      uniq(map(this._layerSources, 'dimensions.x')).length > 1
    ) {
      throw new Error('Tile dimensions are not uniform across channels');
    }

    const referenceSource = first(this._layerSources);

    this.shape = [
      osdChannelSources?.length || 0,
      referenceSource.source.dimensions.y,
      referenceSource.source.dimensions.x,
      this.meta.photometricInterpretation === PhotometricInterpretation.RGB ? 3 : 1,
    ];
  }

  public getUniqueId() {
    const urlIdParts = map(this._layerSources, ({ baseUrl }) => replace(baseUrl, urlPrefix, ''));
    return join(urlIdParts, '|');
  }

  public getTileURL = (
    { layerIndex = 0, x = 0, y = 0, z }: PixelSourceSelection<['layerIndex', 'z', 'y', 'x']>,
    debug?: boolean
  ) => {
    const osdTileSource = this._layerSources[layerIndex];
    if (!osdTileSource) {
      console.error(`Invalid channel ${layerIndex}`);
      return null;
    }

    const dziZoomLevel = z + this.maxLevel;
    const indexTooSmall = Math.min(x, y) < 0; // Negative coordinates are invalid
    const maxIndex = 2 ** Math.max(dziZoomLevel - this.minLevel, 0);
    const indexTooLarge = Math.max(x, y) >= maxIndex;
    const tileExists = osdTileSource.source.tileExists(dziZoomLevel, x, y);
    if (
      indexTooSmall ||
      indexTooLarge || // Coordinates are out of bounds
      !tileExists
    ) {
      if (this._debug || debug) {
        console.error(`Invalid tile coordinates ${x}, ${y}, ${z} (= ${dziZoomLevel})`, {
          indexTooLarge,
          indexTooSmall,
          tileExists,
          maxIndex,
        });
      }
      return null;
    }

    const { width, height } = this.getImageSize();
    const maxTileX = Math.ceil(width / osdTileSource.source.getTileWidth(dziZoomLevel)) - 1;
    const maxTileY = Math.ceil(height / osdTileSource.source.getTileHeight(dziZoomLevel)) - 1;
    if (x > maxTileX || y > maxTileY) {
      if (this._debug || debug) {
        console.error(
          `Tile coordinates ${x}, ${y}, ${z} (= ${dziZoomLevel}) are out of bounds for image size ${width}x${height} - max tile ${maxTileX}x${maxTileY}`
        );
      }
      return null;
    }

    if (dziZoomLevel < 0 || dziZoomLevel > this.maxLevel) {
      if (this._debug || debug) {
        console.error(
          `Zoom level ${z} (= ${dziZoomLevel}) is out of bounds for minLevel ${this.minLevel} and maxLevel ${this.maxLevel}`
        );
      }
      return null;
    }

    const tileUrl = osdTileSource.source.getTileUrl(dziZoomLevel, x, y);
    return `${osdTileSource.baseUrl}/${tileUrl}`;
  };

  public getTileDimensions(z: number): { width: number; height: number } {
    const actualZ = z + this.maxLevel;
    const tileWidths = map(this._layerSources, ({ source }) => source.getTileWidth(actualZ));
    const uniqueTileWidths = uniq(tileWidths);
    if (uniqueTileWidths.length > 1) {
      throw new Error('Tile widths are not uniform across channels');
    }

    const tileHeights = map(this._layerSources, ({ source }) => source.getTileHeight(actualZ));
    const uniqueTileHeights = uniq(tileHeights);
    if (uniqueTileHeights.length > 1) {
      throw new Error('Tile heights are not uniform across channels');
    }
    return {
      width: first(uniqueTileWidths),
      height: first(uniqueTileHeights),
    };
  }

  public getTileSize(): number {
    const tileSizes: number[] = map(this._layerSources, 'metadata.tileSize');
    const uniqueTileSizes = uniq(tileSizes);
    if (uniqueTileSizes.length > 1) {
      throw new Error('Tile sizes are not uniform');
    }
    return first(uniqueTileSizes);
  }

  public getOverlapPixels(): number {
    const overlapPixels: number[] = map(this._layerSources, 'metadata.tileOverlap');
    const uniqueOverlapPixels = uniq(overlapPixels);
    if (uniqueOverlapPixels.length > 1) {
      throw new Error('Overlap pixels are not uniform across channels');
    }
    return first(uniqueOverlapPixels);
  }

  public getImageSize(): { width: number; height: number } {
    return { height: this.shape[1], width: this.shape[2] };
  }

  onTileError(err: Error) {
    console.error(err);
  }
}
