import DeckGL, { DeckGLProps, DeckGLRef } from '@deck.gl/react/typed';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { includes, times } from 'lodash';
import { EditableGeoJsonLayer } from 'nebula.gl';
import React from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { MAX_VIEWERS } from 'components/Procedure/SlidesViewer/constants';
import { SlideWithChannelAndResults } from 'components/Procedure/useSlideChannelsAndResults/utils';
import { HeatmapsImagePyramids, ImagePyramid } from 'components/Procedure/useSlideImages';
import { useAnnotationQueryParams } from 'services/annotations/useAnnotationQueryParams';
import OrthographicMapView from './OrthographicMapview';
import { PMTFeaturePopover } from './PMTFeaturePopover';
import PinComments from './PinComments';
import { SlideViewerOverlays } from './SlideViewerOverlays';
import { AnnotationsContextMenu } from './layers/EditAnnotationLayers/AnnotationsContextMenu';
import { useSlideLayers } from './layers/useSlideLayers';
import { deckGLViewerStates } from './slidesViewerState';

const BASE_MAP_VIEW_ID = 'baseMap';

export const viewerHoverData = times(MAX_VIEWERS, () => signal<Parameters<DeckGLProps['onHover']> | null>(null));

const onHoverHandlers: DeckGLProps['onHover'][] = times(MAX_VIEWERS, (viewerIndex) => (info, event) => {
  const hoverDataSignal = viewerHoverData[viewerIndex];
  if (hoverDataSignal) {
    hoverDataSignal.value = [info, event];
  }
});

export const viewerClickData = times(MAX_VIEWERS, () => signal<Parameters<DeckGLProps['onClick']> | null>(null));

const onClickHandlers: DeckGLProps['onClick'][] = times(MAX_VIEWERS, (viewerIndex) => (info, event) => {
  const clickDataSignal = viewerClickData[viewerIndex];
  if (clickDataSignal) {
    clickDataSignal.value = [info, event];
  }
});

export const viewerInteractionCursor = times(MAX_VIEWERS, () => signal<string | null>(null));

const getCursorForComments = (viewerIndex: number) => {
  const layerId = viewerHoverData[viewerIndex]?.value?.[0].layer?.id;
  return layerId === 'comments-edit-layer' ? 'pointer' : 'inherit';
};

export const SlideDeckGLViewer: React.FC<
  React.PropsWithChildren<{
    slide: SlideWithChannelAndResults;
    baseImagePyramids: ImagePyramid;
    heatmapsImagePyramids: HeatmapsImagePyramids;
    displaySlideId?: boolean;
    viewSize: { width: number; height: number };
    onViewStateChange: DeckGLProps['onViewStateChange'];
    hideComments?: boolean;
    procedureId: number;
  }>
> = ({
  slide,
  baseImagePyramids,
  heatmapsImagePyramids,
  hideComments,
  procedureId,
  displaySlideId,
  viewSize,
  onViewStateChange,
}) => {
    useSignals();
    const viewerSlidesStates = deckGLViewerStates[slide?.viewerIndex]?.value;
    const viewState = viewerSlidesStates?.[slide?.id];

    const { layers, interactiveLayer, numLoadingBaseLayers, numBaseLayers, numLoadingHeatmapLayers, numHeatmapLayers } =
      useSlideLayers({
        slide,
        baseImagePyramids,
        heatmapsImagePyramids,
        viewSize,
        viewState,
        hideComments,
        procedureId,
      });

    const hasInteractiveLayer = Boolean(interactiveLayer);

    const deckGlRef = React.useRef<DeckGLRef>(null);

    const [hideDeckGLOverlays] = useQueryParam('hideDeckGLOverlays', BooleanParam);
    const [showPmtFeaturePopover] = useQueryParam('showPmtFeaturePopover', BooleanParam);

    const interactiveLayerGetCursor = (interactiveLayer as any)?.getCursor?.bind(interactiveLayer);

    const getCursor: DeckGLProps['getCursor'] = React.useCallback(
      (...props) => {
        if (interactiveLayerGetCursor) {
          const cursor: string = interactiveLayerGetCursor(...props);
          if (viewerInteractionCursor?.[slide?.viewerIndex]) {
            if (cursor && viewerInteractionCursor[slide?.viewerIndex].value !== cursor) {
              viewerInteractionCursor[slide?.viewerIndex].value = cursor;
            }
          }
          return viewerInteractionCursor[slide?.viewerIndex].value;
        } else {
          return getCursorForComments(slide.viewerIndex);
        }
      },
      [interactiveLayerGetCursor, slide?.viewerIndex]
    );

    const canInteractWithLayer = hasInteractiveLayer && Boolean(interactiveLayerGetCursor);

    const viewerInteractiveCursor = viewerInteractionCursor[slide?.viewerIndex].value;
    const isInteracting = !hasInteractiveLayer || !includes(['grab', 'grabbing'], viewerInteractiveCursor);

    const basemapView = React.useMemo(
      () =>
        new OrthographicMapView({
          id: BASE_MAP_VIEW_ID,
          controller: {
            inertia: 200,
            dragPan: !canInteractWithLayer || !isInteracting,
            doubleClickZoom: !hasInteractiveLayer,
          },
          ignorePitch: true,
          height: viewSize.height,
          width: viewSize.width,
        }),
      [viewSize, canInteractWithLayer, isInteracting, hasInteractiveLayer]
    );

    const [clickedLayer, clickEvent] = viewerClickData[slide.viewerIndex]?.value || [null, null];
    const isRightClickEvent = clickEvent?.rightButton;
    const isEditableGeoJsonLayer = clickedLayer?.layer instanceof EditableGeoJsonLayer;

    const { annotationsActive } = useAnnotationQueryParams();

    return (
      Boolean(viewState) && (
        <DeckGL
          _pickable={true}
          ref={deckGlRef}
          views={basemapView}
          layers={layers}
          initialViewState={viewerSlidesStates?.[slide?.id]}
          onViewStateChange={onViewStateChange}
          pickingRadius={2}
          getCursor={getCursor}
          onHover={onHoverHandlers[slide?.viewerIndex]}
          onDragStart={(info, event) => {
            // Handle the drag event starting from annotation option selection
            if (!event.leftButton) {
              console.warn('Drag start with non-left button');
              event.preventDefault();
            }
          }}
          onClick={(info, event) => {
            if (event.srcEvent.defaultPrevented) {
              event.preventDefault();
              return;
            }
            onClickHandlers?.[slide?.viewerIndex]?.(info, event);
          }}
          glOptions={{
            antialias: false,
          }}
        >
          {!hideDeckGLOverlays
            ? ({ viewport }) => (
              <>
                <SlideViewerOverlays
                  displaySlideId={displaySlideId}
                  slide={slide}
                  viewSize={viewSize}
                  viewState={viewerSlidesStates?.[slide?.id]}
                  numLoadingBaseLayers={numLoadingBaseLayers}
                  numBaseLayers={numBaseLayers}
                  numLoadingHeatmapLayers={numLoadingHeatmapLayers}
                  numHeatmapLayers={numHeatmapLayers}
                />
                {!hideComments && (
                  <PinComments
                    slideId={slide.id}
                    procedureId={procedureId}
                    viewerIndex={slide.viewerIndex}
                    viewport={viewport}
                  />
                )}
                {showPmtFeaturePopover && <PMTFeaturePopover slide={slide} viewport={viewport} />}
                {annotationsActive && (
                  <AnnotationsContextMenu
                    slide={slide}
                    baseImagePyramids={baseImagePyramids}
                    viewport={viewport}
                    rightClickInfo={isRightClickEvent && isEditableGeoJsonLayer ? clickedLayer : undefined}
                  />
                )}
              </>
            )
            : null}
        </DeckGL>
      )
    );
  };
