import { useSignals } from '@preact/signals-react/runtime';
import { QueryFunctionContext } from '@tanstack/react-query';
import { filter, find, flatMap, isEmpty, map, omit, some, uniqBy } from 'lodash';

import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  DziChannel,
  DziMetadata,
  dziChannelFromManifestUrl,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/OSDPixelSource/utils';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import {
  SlideWithChannelAndResults,
  getAllFlatMapDeepHeatmapsFromSlide,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { Slide } from 'interfaces/slide';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { useQueriesWithErrorAndRetrySnackbar } from 'utils/useQueryWithErrorAndRetrySnackbar';

// Passed from Webpack
declare const IMAGE_HOST: string;

export const slideToManifestUrl = (slide: Pick<Slide, 'id' | 'labId' | 'format'>): string =>
  slide?.id &&
  slide?.labId &&
  slide?.format &&
  `${IMAGE_HOST}/manifest/${slide.id}/?lab_id=${slide.labId}&slide_format=${slide.format}`;

export const canLoadBaseSlide = (
  slide: Pick<SlideWithChannelAndResults, 'stainingType' | 'channelsMetadata' | 'id' | 'labId' | 'format'>
): boolean =>
  (slide?.stainingType !== MULTIPLEX_STAIN_ID && Boolean(slideToManifestUrl(slide))) ||
  !isEmpty(slide.channelsMetadata);

export const getDziUrlFromSlideManifest = async (dziUrl: string) => {
  const request = await fetch(dziUrl);
  const manifest = await request.json();
  return String(manifest.slide_url);
};

export interface SlideDziChannel extends DziChannel {
  metadata: Slide & DziMetadata;
}

export interface SlideSource {
  id: string;
  slideId: string;
  tileSource?: SlideDziChannel;
}

interface SlidesSourcesProps {
  slides: SlideWithChannelAndResults[];
  enabled: boolean;
}

export const useSlidesBaseSources = ({ slides, enabled = true }: SlidesSourcesProps) => {
  const responses = useQueriesWithErrorAndRetrySnackbar<unknown, SlideSource>({
    snackbarOptions: { persist: true, preventDuplicate: true },
    // Only query each slide once
    queries: flatMap(uniqBy(slides, 'id'), (slide) => {
      const { channelsMetadata, viewerIndex, ...slideInfo } = slide;
      const channelsWithUrls = filter(channelsMetadata, ({ url }) => Boolean(url));

      if (!isEmpty(channelsWithUrls)) {
        return map(channelsWithUrls, (channel) => ({
          enabled: enabled && canLoadBaseSlide(slide) && Boolean(channel.url),
          queriedEntityName: `Slide ${slide.id} - Channel ${channel.id}`,
          queryKey: ['channel', slide.id, channel.id, channel.url],
          queryFn: async ({ signal }: QueryFunctionContext): Promise<SlideSource> => {
            const { source, metadata, baseUrl } = await dziChannelFromManifestUrl(channel.url, signal);
            if (signal?.aborted) {
              return null;
            }
            return {
              ...channel,
              slideId: slide.id,
              tileSource: {
                source,
                metadata: { ...metadata, ...slideInfo },
                baseUrl,
                label: channel.channelName,
              },
            };
          },
        }));
      } else {
        return {
          enabled: enabled && canLoadBaseSlide(slide),
          queriedEntityName: `Slide ${slide.id} - Base Layer`,
          queryKey: ['slide', slide.id, slide.format, slide.labId],
          queryFn: async ({ signal }: QueryFunctionContext): Promise<SlideSource> => {
            const slideManifestURL = slideToManifestUrl(slide);
            if (!slideManifestURL) {
              return;
            }
            const rgbUrl = await getDziUrlFromSlideManifest(slideManifestURL);
            if (signal?.aborted) {
              return null;
            }
            if (!rgbUrl) {
              console.warn('No RGB url found for slide', slide);
              return;
            }
            const { source, metadata, baseUrl } = await dziChannelFromManifestUrl(rgbUrl, signal);
            const tileSource: SlideDziChannel = {
              source,
              metadata: { ...metadata, ...slideInfo },
              label: 'RGB',
              baseUrl,
              isRgb: true,
            };
            if (signal?.aborted) {
              return null;
            }
            if (slide.sizeCols && source.dimensions.x !== slide.sizeCols) {
              console.warn('Slide width does not match manifest width', {
                manifest: source.dimensions.x,
                slide: slide.sizeCols,
              });
            }
            if (slide.sizeRows && source.dimensions.y !== slide.sizeRows) {
              console.warn('Slide height does not match manifest height', {
                manifest: source.dimensions.y,
                slide: slide.sizeRows,
              });
            }
            return { slideId: slide.id, tileSource, id: slide.id };
          },
        };
      }
    }),
  });
  return responses;
};

const shouldLoadHeatmap = (
  slide: Pick<SlideWithChannelAndResults, 'id' | 'heatmapResults' | 'stainingType' | 'channelsMetadata'>,
  heatmap: FeatureMetadata
) => {
  if (!heatmap?.heatmapUrl || heatmap?.heatmapType !== 'dzi' || !canLoadBaseSlide(slide)) {
    return false;
  }

  const isPublishedHeatmap = find(slide.heatmapResults?.publishedResults, { id: heatmap.id });
  if (isPublishedHeatmap) {
    return true;
  }

  return some(
    slidesLayerVisualizationSettings,
    (viewerSlideLayerVisualizationSettings) =>
      viewerSlideLayerVisualizationSettings?.value?.[slide.id]?.[heatmap.id]?.value?.selected
  );
};

export const useSlidesHeatmapDziSources = ({ slides, enabled }: SlidesSourcesProps) => {
  useSignals();

  const dziHeatmapsWithUrls: Array<FeatureMetadata & { slideInfo: Omit<SlideWithChannelAndResults, 'viewerIndex'> }> =
    // Only query each slide once
    flatMap(uniqBy(slides, 'id'), (slide) =>
      filter(
        map(getAllFlatMapDeepHeatmapsFromSlide(slide), (heatmap) => ({
          slideInfo: omit(slide, 'viewerIndex'),
          ...heatmap,
        })),
        ({ heatmapUrl, heatmapType }): boolean => Boolean(heatmapUrl) && heatmapType === 'dzi'
      )
    );

  return useQueriesWithErrorAndRetrySnackbar<
    unknown,
    FeatureMetadata & { tileSource: SlideDziChannel; slideId: string }
  >({
    snackbarOptions: { persist: true, preventDuplicate: true },
    queries: map(dziHeatmapsWithUrls, ({ slideInfo, ...heatmap }) => {
      return {
        enabled: enabled && shouldLoadHeatmap(slideInfo, heatmap),
        queriedEntityName: `Slide ${slideInfo.id} - Heatmap Layer ${heatmap.id}`,
        queryKey: ['heatmap', slideInfo.id, heatmap.id, heatmap.heatmapUrl],
        queryFn: async ({
          signal,
        }: QueryFunctionContext): Promise<FeatureMetadata & { tileSource: SlideDziChannel; slideId: string }> => {
          if (!heatmap?.heatmapUrl) {
            return null;
          }
          const { source, metadata, baseUrl } = await dziChannelFromManifestUrl(heatmap.heatmapUrl, signal);
          if (signal?.aborted) {
            return null;
          }
          return {
            ...heatmap,
            slideId: slideInfo.id,
            tileSource: {
              source,
              metadata: { ...metadata, ...slideInfo, ...heatmap },
              baseUrl,
              label: heatmap.displayName,
              isRgb: true,
            },
          };
        },
      };
    }),
  });
};
