import AdjustIcon from '@mui/icons-material/Adjust';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import CloseIcon from '@mui/icons-material/Close';
import PentagonOutlinedIcon from '@mui/icons-material/PentagonOutlined';
import { Checkbox, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ColorPicker } from 'components/atoms/ColorPicker';
import { TodoOption } from 'interfaces/annotation';
import { map } from 'lodash';
import React from 'react';

interface ClassesToAnnotateListProps {
  classesToAnnotate: TodoOption[];
  onDeleteTodo?: (id: string) => void;
  onColorChange?: (id: string, color: string) => void;
  isSelectable?: boolean;
  isTodoSelected?: (id: string) => boolean;
  toggleSelection?: (id: string, checked: boolean) => void;
  isEditable?: boolean;
}

const ClassesToAnnotateList: React.FC<ClassesToAnnotateListProps> = ({
  classesToAnnotate,
  onDeleteTodo,
  onColorChange,
  isSelectable,
  isTodoSelected,
  toggleSelection,
  isEditable,
}) => {
  if (isEditable && !onDeleteTodo) {
    console.warn(
      'ClassesToAnnotateList: onDeleteTodo is not defined but isEditable is true- (not showing delete button)'
    );
  }
  if (isEditable && !onColorChange) {
    console.warn(
      'ClassesToAnnotateList: onColorChange is not defined but isEditable is true- (not allowing to edit color)'
    );
  }
  if (isSelectable && (!isTodoSelected || !toggleSelection)) {
    console.warn(
      'ClassesToAnnotateList: isTodoSelected or toggleSelection is not defined but isSelectable is true- (not showing checkbox)'
    );
  }

  return (
    <List>
      {map(classesToAnnotate, (classToAnnotate) => (
        <ClassToAnnotateListItem
          key={classToAnnotate.name}
          item={classToAnnotate}
          onDelete={isEditable && Boolean(onDeleteTodo) ? () => onDeleteTodo(classToAnnotate.name) : undefined}
          onColorChange={
            isEditable && Boolean(onColorChange)
              ? (newColor) => onColorChange(classToAnnotate.name, newColor)
              : undefined
          }
          isSelectable={isSelectable && Boolean(isTodoSelected) && Boolean(toggleSelection)}
          isSelected={isTodoSelected && isTodoSelected(classToAnnotate.name)}
          toggleSelection={toggleSelection ? (checked) => toggleSelection(classToAnnotate.name, checked) : undefined}
          isEditable={isEditable}
        />
      ))}
    </List>
  );
};

interface ClassToAnnotateListItemProps {
  item: TodoOption;
  onColorChange?: (color: string) => void;
  onDelete?: () => void;
  isSelectable?: boolean;
  isSelected?: boolean;
  toggleSelection?: (checked: boolean) => void;
  isEditable?: boolean;
}

const ClassToAnnotateListItem: React.FC<ClassToAnnotateListItemProps> = ({
  item,
  onColorChange,
  onDelete,
  isSelectable,
  isSelected,
  toggleSelection,
  isEditable,
}) => {
  const { className, color, displayName, name } = item;

  const handleColorChange = (newColor: string) => {
    onColorChange(newColor);
  };

  return (
    <ListItem
      secondaryAction={
        isEditable && (
          <IconButton edge="end" onClick={() => onDelete()}>
            <CloseIcon />
          </IconButton>
        )
      }
    >
      {isSelectable && Boolean(toggleSelection) && (
        <ListItemIcon sx={{ minWidth: '40px' }}>
          <Checkbox checked={isSelected} onChange={(e) => toggleSelection(e.target.checked)} />
        </ListItemIcon>
      )}

      {/* the default minWidth is 56px witch is too big for this case */}
      <ListItemIcon sx={{ minWidth: '40px' }}>
        <ColorPicker
          disableEditing={!isEditable || !handleColorChange}
          currentColor={color}
          onColorChange={handleColorChange}
        />
      </ListItemIcon>
      <ListItemIcon sx={{ minWidth: '40px' }}>
        {className === 'polygon' ? (
          name.includes('roi') ? (
            <BorderStyleIcon />
          ) : (
            <PentagonOutlinedIcon />
          )
        ) : (
          <AdjustIcon />
        )}
      </ListItemIcon>
      <ListItemText
        primary={displayName ?? name}
        primaryTypographyProps={{
          variant: 'subtitle2',
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </ListItem>
  );
};

export default ClassesToAnnotateList;
