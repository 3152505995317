import CloseIcon from '@mui/icons-material/Close';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { createAnnotationAssignment } from 'api/annotationAssignments';
import { AnnotationAssignment, TodoOption } from 'interfaces/annotation';
import { filter, includes, map } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import queryClient from 'utils/queryClient';
import { useCasesParams } from 'utils/useCasesParams';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';
import * as yup from 'yup';
import CreateAnnotationAssignmentSection from './CreateAnnotationAssignmentSection';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const CreateAnnotationAssignmentDialog: React.FC<ModalProps> = ({ open, onClose }) => {
  const assignmentCreationForm = useForm<AssignmentCreationFormValues>({
    mode: 'onChange',
    resolver: yupResolver(assignmentCreationSchema),
  });

  const createAnnotationAssignmentMutation = useMutationWithErrorSnackbar({
    mutationDescription: 'createAnnotationAssignment',
    mutationFn: createAnnotationAssignment,
    onSuccess: () => {
      enqueueSnackbar('Annotation assignment created successfully', { variant: 'success' });
      queryClient.invalidateQueries(['annotationAssignments']);
      onClose();
    },
  });

  const handleDeleteClassToAnnotate = (id: string) => {
    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    assignmentCreationForm.setValue(
      'classesToAnnotate',
      filter(previousClassesToAnnotate, (previousClassToAnnotate) => previousClassToAnnotate.name !== id)
    );
  };

  const handleColorChange = (id: string, color: any) => {
    const previousClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');
    assignmentCreationForm.setValue(
      'classesToAnnotate',
      map(previousClassesToAnnotate, (previousClassToAnnotate) => {
        if (previousClassToAnnotate.name === id) {
          return {
            ...previousClassToAnnotate,
            color,
          };
        }
        return previousClassToAnnotate;
      })
    );
  };

  const handleClassesToAnnotateChange = (newClassesToAnnotate: TodoOption[]) => {
    const prevClassesToAnnotate = assignmentCreationForm.watch('classesToAnnotate');

    const prevIds = map(prevClassesToAnnotate, 'name');
    // Add newly selected items at the end
    const added = filter(newClassesToAnnotate, (newClassToAnnotate) => !includes(prevIds, newClassToAnnotate.name));

    // Keep only the ids that are still selected, in their original order
    const retained = filter(prevClassesToAnnotate, (prevClassToAnnotate) =>
      includes(map(newClassesToAnnotate, 'name'), prevClassToAnnotate.name)
    );

    const newClassesToAnnotateWithPreviousOrder = [...retained, ...added];

    assignmentCreationForm.setValue('classesToAnnotate', newClassesToAnnotateWithPreviousOrder);
    // validate to update errors
    assignmentCreationForm.trigger('classesToAnnotate');
  };

  const handleClose = (event: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const { casesParams } = useCasesParams();

  const handleApply: SubmitHandler<AssignmentCreationFormValues> = async (data) => {
    const newAnnotationAssignment: Pick<AnnotationAssignment, 'name' | 'todos'> = {
      name: data.assignmentName,
      todos: [
        {
          todo: 'todo',
          type: 'annotation_marker',
          options: data.classesToAnnotate,
        },
      ],
    };

    createAnnotationAssignmentMutation.mutate({ ...newAnnotationAssignment, ...casesParams });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialogContent-root': {
          padding: 0,
        },
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        Create Annotation Assignment
        <IconButton aria-label="close" onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <CreateAnnotationAssignmentSection
          assignmentCreationForm={assignmentCreationForm}
          onDeleteClassToAnnotate={handleDeleteClassToAnnotate}
          onColorChange={handleColorChange}
          onClassesToAnnotateChange={handleClassesToAnnotateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={assignmentCreationForm.handleSubmit(handleApply)}
          color="primary"
          variant="contained"
          disabled={createAnnotationAssignmentMutation.isLoading}
        >
          {createAnnotationAssignmentMutation.isLoading ? 'Creating...' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export interface AssignmentCreationFormValues {
  assignmentName: string;
  classesToAnnotate: TodoOption[];
}

const assignmentCreationSchema = yup.object({
  assignmentName: yup.string().required('Assignment name is required'),
  classesToAnnotate: yup.array().required().min(1, 'At least one class is required'),
});

export default CreateAnnotationAssignmentDialog;
