import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { isEmpty, map } from 'lodash';
import React, { useState } from 'react';

import Loader from 'components/Loader';
import { StainType } from 'interfaces/stainType';
import { useCreateCellRulePanelMutation } from 'utils/queryHooks/cellRule/useCellRuleMutations';
import useStudyChannelMarkerTypes from 'utils/queryHooks/useStudyChannelMarkerTypes';
import StainTypeSelectDropdown from '../CellRuleOptions/StainTypeDropdown';

const AddCellPanelDialog: React.FunctionComponent<{
  studyId: string;
}> = ({ studyId }) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');

  const studyMarkerTypes = useStudyChannelMarkerTypes(studyId, { enabled: open });

  const isLoadingMarkerTypes = studyMarkerTypes.isCheckingStudyChannels || studyMarkerTypes.isLoadingStainTypeOptions;

  const [selectedStainTypes, setSelectedStainTypes] = useState<StainType[]>([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedStainTypes([]);
  };

  const createMutation = useCreateCellRulePanelMutation();

  const handleSave = () => {
    const stainTypeIds = map(selectedStainTypes, 'id');
    createMutation.mutate(
      { studyId, label, stainTypeIds },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const setStainTypesToStudy = () => {
    setSelectedStainTypes(studyMarkerTypes.markerTypes);
  };

  return (
    <>
      <Button onClick={handleOpen} startIcon={<AddIcon />}>
        Add new Panel
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Cell Panel</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{
              padding: 2,
            }}
          >
            <Grid xs={12} item>
              <TextField label="Label" value={label} onChange={handleLabelChange} />
            </Grid>
            <Grid item>
              <StainTypeSelectDropdown
                selectedStainTypes={selectedStainTypes}
                onSelectStainTypes={setSelectedStainTypes}
                mifMarkersOnly
              />
            </Grid>
            <Grid item>
              <Button
                onClick={setStainTypesToStudy}
                disabled={isLoadingMarkerTypes || isEmpty(studyMarkerTypes.markerTypes)}
              >
                Select all study marker types {isLoadingMarkerTypes && <Loader size="small" />}
              </Button>
            </Grid>

            <Grid item>
              <Typography variant="caption">
                Select the cell types and markers that will be imported from the global panel, for your convenience you
                can select all the stain types that are in the study
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={createMutation.isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            disabled={!label || createMutation.isLoading}
          >
            {createMutation.isLoading ? (
              <>
                {'Saving... '} <Loader size="small" />
              </>
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCellPanelDialog;
