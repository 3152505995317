import { drop, filter, forEach, includes, isEmpty, isString, map, uniq } from 'lodash';
import { pipe, replace } from 'lodash/fp';
import moment from 'moment';
import papaparse from 'papaparse';
import { stringify } from 'qs';

import { getFeatureKeysByStudyAndOrchestration } from 'api/features';
import { getCellsTablePresignedUrls } from 'api/study';
import { ResultsMode } from 'interfaces/experimentResults';
import { FeatureFormatter } from 'interfaces/featureFormatter';
import { apiRequestHandlerPromise } from 'utils/apiRequestHandler';
import { findFormatterByKey, getFeatureDisplayName, getFeatureFormattedValue } from 'utils/features';
import { getFeaturesKeysObj } from 'utils/features/getFeaturesKeysObjects';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';

const replaceTeXWithUnicode = pipe(replace(/\\mu ?/g, 'µ'), replace(/\^2/g, '²')); // a bit of a hack to replace TeX with unicode

const exportData = async ({
  endpoint,
  queryParams,
  fileNamePrefix,
  context,
}: {
  context?: FormatBracketsOptions;
  endpoint: string;
  queryParams: Record<string, string> | string;
  fileNamePrefix: string;
}) => {
  const url = `${endpoint}?${isString(queryParams) ? queryParams : stringify(queryParams)}`;

  try {
    const result = await apiRequestHandlerPromise({
      url,
      method: 'GET',
    });

    const resultObj: { data: string[][] } = papaparse.parse(result.csv_data);

    const formattedResults = context ? formatResults(resultObj, context) : resultObj.data;

    const csvData = papaparse.unparse(formattedResults);

    const blob = new Blob([csvData], { type: 'text/csv' });
    const date = moment().format('DD.MM:YYYY hh:mm:ss a');
    const fileName = `${fileNamePrefix}-${date}.csv`;
    downloadBlob(blob, fileName);
  } catch (error) {
    console.error(`Got an error exporting data: ${error}`);
  }
};

export const downloadBlob = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const exportFeatures = ({
  studyId,
  context,
  highlightedOnly,
  resultsMode,
  orchestrationId,
}: {
  studyId: string;
  context: FormatBracketsOptions;
  highlightedOnly?: boolean;
  resultsMode?: ResultsMode;
  orchestrationId?: string;
}) => {
  return exportData({
    endpoint: 'features',
    queryParams: {
      studyId,
      ...(highlightedOnly && { highlightedFeaturesOnly: 'true' }),
      ...(resultsMode && { resultsMode }),
      ...(resultsMode === ResultsMode.Manual && { orchestrationId }),
    },
    fileNamePrefix: `studyId-${studyId}`,
    context,
  });
};

export const exportFormResults = async (formId: string) => {
  return exportData({
    endpoint: `forms/${formId}/responses/aggregated_table`,
    queryParams: { formId },
    fileNamePrefix: `formId-${formId}`,
  });
};

export const exportQcResults = ({
  labId,
  studyId,
  orchestrationId,
  context,
}: {
  labId: string;
  studyId: string;
  orchestrationId?: string;
  context: FormatBracketsOptions;
}) => {
  return exportData({
    context,
    endpoint: 'label_results',
    queryParams: { filters: JSON.stringify({ studyId, orchestrationId }), labId },
    fileNamePrefix: `label_results_for_studyId-${studyId}`,
  });
};

export const exportQcResultsFiltered = ({
  context,
  encodedFilters,
}: {
  encodedFilters: string;
  context: FormatBracketsOptions;
}) => {
  return exportData({
    endpoint: 'label_results',
    queryParams: encodedFilters,
    fileNamePrefix: `exported_label_results`,
    context,
  });
};

export const exportCustomerResults = async (labId: string, studyId: string) => {
  const urlParams = new URLSearchParams();
  urlParams.set('studyId', studyId);
  urlParams.set('labId', labId);
  const url = `presignedUrls?${urlParams.toString()}`;

  try {
    const result = await apiRequestHandlerPromise({
      url,
      method: 'GET',
    });
    const { presignedUrls } = result;
    presignedUrls.forEach((presignedUrl: string) => {
      window.open(presignedUrl);
    });
  } catch (error) {
    console.error('Got an error getting customer results', error);
  }
};

export const exportFeatureNames = async ({
  studyId,
  context,
  orchestrationId,
}: {
  studyId: string;
  context: FormatBracketsOptions;
  orchestrationId?: string;
}) => {
  const featuresKeys = (
    await getFeatureKeysByStudyAndOrchestration(studyId, orchestrationId, false, false, ResultsMode.Manual)
  ).featuresKeys;
  const featuresKeysObj = getFeaturesKeysObj(featuresKeys, context);

  const csvData = papaparse.unparse(featuresKeysObj);
  const blob = new Blob([csvData], { type: 'text/csv' });
  const urlDownload = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', urlDownload);
  const date = moment().format('DD.MM:YYYY hh:mm:ss a');
  const fileName = `features names for studyId-${studyId} ${date}`;
  a.setAttribute('download', `${fileName}.csv`);
  a.click();

  return featuresKeysObj;
};

const stainColumnIndex = 3;

function formatResults(resultObj: { data: string[][] }, context: FormatBracketsOptions) {
  // Get unique stains without header and empty values
  const stains = uniq(
    map(
      filter(drop(resultObj.data, 1), (row) => !isEmpty(row[stainColumnIndex])),
      (row) => row[stainColumnIndex]
    )
  );

  const headersFeatureFormatters: (FeatureFormatter & { nonFormattedUnits?: string })[] = [];

  const resultHeaderFormatted = map(resultObj.data[0], (header: string) => {
    const featureDisplayName = getFeatureDisplayName(header, { ...context, addStain: stains.length > 1 });
    const headerFeatureFormatter = findFormatterByKey(header, {
      featureDisplayName,
      context: {
        ...context,
        addStain: stains.length > 1,
      },
    });

    headersFeatureFormatters.push(headerFeatureFormatter);
    let unicodeFormattedUnits = '';

    if (includes(headerFeatureFormatter?.valueFormat, '%')) {
      unicodeFormattedUnits = ` [%]`;
    } else if (headerFeatureFormatter?.nonFormattedUnits) {
      unicodeFormattedUnits = ` [${replaceTeXWithUnicode(headerFeatureFormatter.nonFormattedUnits)}]`;
    }

    return `${headerFeatureFormatter.nameOverride || featureDisplayName}${unicodeFormattedUnits}`;
  });

  const resultValueFormatted = map(drop(resultObj.data, 1), (row) => {
    return map(row, (cell, cellIndex) => {
      const formatter = headersFeatureFormatters[cellIndex];
      if (!isEmpty(formatter)) {
        return getFeatureFormattedValue(cell, formatter?.exportValueFormat || formatter?.valueFormat, null, true);
      }
      return cell;
    });
  });
  return [resultHeaderFormatted, ...resultValueFormatted];
}
export const exportCellsTable = async (
  studyId: string,
  orchestrationId: string,
  resultsMode: ResultsMode,
  onFailed?: () => void
) => {
  try {
    const cellsTablePresignedUrls = await getCellsTablePresignedUrls(studyId, orchestrationId, resultsMode);
    forEach(cellsTablePresignedUrls, (presignedUrl) => {
      window.open(presignedUrl);
    });
  } catch (error) {
    console.error('Got an error getting cells table', error);
    onFailed();
  }
};
