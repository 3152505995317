import { Button, Grid } from '@mui/material';
import { compact, forEach, map, uniq } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, useQueryParam } from 'use-query-params';

import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import useResultsMutation from '../useResultsMutation';

interface Props {
  slideId: string;
  viewerIndex: number;
  studyId: string;
  internalHeatmaps: boolean;
  heatmaps: FeatureMetadata[];
}

export const UnpublishResultsButton: React.FC<Props> = ({
  slideId,
  viewerIndex,
  studyId: studyIdFromProps,
  internalHeatmaps,
  heatmaps,
}) => {
  const { queryParams } = useEncodedFilters();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const [isPublishMode] = useQueryParam(`isPublishModeViewer${viewerIndex}`, BooleanParam);

  const params = useParams();
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);
  const studyId = studyIdFromProps || queryParams.filters?.studyId;

  const { handleFieldSave } = useResultsMutation(caseId, studyId);
  const handleDisapproveResult = (orchestrationId: string) => {
    // TODO: Add support to update results status by studyId without caseId
    // 'Boolean(caseId)' is for scenarios that caseId is null (isNaN(null) is false)
    const hasCaseId = Boolean(caseId) && !isNaN(caseId);
    if (!hasCaseId) {
      console.error('caseId is not a number', caseId);
      return;
    }
    const heatmapResultTypes = uniq(compact(map(heatmaps, 'resultType')));
    const heatmapFlowClassNames = uniq(
      compact(map(heatmaps, ({ resultType, flowClassName }) => (resultType! ? flowClassName : undefined)))
    );
    handleFieldSave({
      orchestrationId,
      slideId,
      fieldName: 'approved',
      value: false,
      resultTypes: heatmapResultTypes,
      flowClassNames: heatmapFlowClassNames,
    });
  };

  const handleDisapproveResults = () => {
    // should be only one orchestrationId
    const uniqOrchestrationIds = uniq(
      // Publishing / unpublishing is done on primaryRunOrchestrationId if it exists, otherwise on orchestrationId
      map(heatmaps, (heatmap) => heatmap.primaryRunOrchestrationId || heatmap.orchestrationId)
    );
    forEach(uniqOrchestrationIds, (orchestrationId) => {
      handleDisapproveResult(orchestrationId);
    });
  };
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false);

  return (
    <>
      {studyId && isPublishMode && !internalHeatmaps && (
        <Grid item>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpenConfirmationDialog(true);
            }}
          >
            Unpublish
          </Button>
        </Grid>
      )}
      {openConfirmationDialog && (
        <ConfirmationModal
          title="Unpublish Results"
          text={'Are you sure you want to unpublish the results?'}
          onConfirm={() => {
            handleDisapproveResults();
            setOpenConfirmationDialog(false);
          }}
          onCancel={() => setOpenConfirmationDialog(false)}
        />
      )}
    </>
  );
};
