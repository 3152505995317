import GL from '@luma.gl/constants';
import { Texture2D } from '@luma.gl/webgl';
import { DecodedPng } from 'fast-png';
import { compact, filter, find, findIndex, first, forEach, groupBy, keys, map, times } from 'lodash';

import { Position } from '@deck.gl/core/typed';
import { MultiScaleImageData } from '../multiScaleImageLayer/utils';
import { getRenderingAttrs } from './utils';

export interface ChannelAtlasAssignment {
  atlasIndex: number;
  x: number;
  y: number;
}

export const initializeSingleChannelAtlas = (
  glContext: WebGLRenderingContext,
  width: number,
  height: number,
  channelsPerAtlas: number,
  attrs: ReturnType<typeof getRenderingAttrs>
) => {
  const squareDim = Math.max(1, Math.ceil(Math.sqrt(channelsPerAtlas)));

  return new Texture2D(glContext, {
    width: squareDim * width,
    height: squareDim * height,

    parameters: {
      // NEAREST for integer data
      [GL.TEXTURE_MIN_FILTER]: attrs.filter,
      [GL.TEXTURE_MAG_FILTER]: attrs.filter,
      // CLAMP_TO_EDGE to remove tile artifacts
      [GL.TEXTURE_WRAP_S]: GL.CLAMP_TO_EDGE,
      [GL.TEXTURE_WRAP_T]: GL.CLAMP_TO_EDGE,
    },
    format: attrs.format,
    dataFormat: attrs.dataFormat,
    type: attrs.type,
  });
};

export const shouldLoadChannel = ({
  channelImage,
  channelIndex,
  layersVisible,
  layerOpacities,
}: {
  channelImage: DecodedPng | ImageData | null | undefined;
  channelIndex: number;
  layersVisible: boolean[];
  layerOpacities: number[];
}) => Boolean(layersVisible[channelIndex] && layerOpacities[channelIndex] > 0 && (channelImage?.data.length ?? 0) > 0);

function getExistingTextureFreeChannelAtlasAssignment(
  channelAtlasAssignments: ChannelAtlasAssignment[],
  maxTextures: number
): ChannelAtlasAssignment | undefined {
  const channelsDistributionPerAtlas = Math.ceil(channelAtlasAssignments.length / maxTextures);
  const squareDim = Math.max(1, Math.ceil(Math.sqrt(channelsDistributionPerAtlas)));
  const channelSpacePerAtlas = squareDim ** 2;

  // Even though we're using square textures, we don't assume that the number of channels is a square number
  const textureCoordinateOptions: Array<[x: number, y: number]> = times(channelSpacePerAtlas, (i) => [
    i % squareDim,
    Math.floor(i / squareDim),
  ]);

  const previousAssignmentsByTexture = groupBy(compact(channelAtlasAssignments), 'atlasIndex');
  const atlasIndexWithFreeSpace = find(
    keys(previousAssignmentsByTexture),
    (atlasIndex) => previousAssignmentsByTexture[atlasIndex].length < channelSpacePerAtlas
  );

  if (atlasIndexWithFreeSpace !== undefined) {
    // If we get here, we have a texture atlas with free space
    const atlasIndex = parseInt(atlasIndexWithFreeSpace, 10);
    if (isNaN(atlasIndex) || atlasIndex === undefined || atlasIndex === null) {
      console.error('Could not parse atlas index', { atlasIndexWithFreeSpace, atlasIndex, channelAtlasAssignments });
      throw new Error(`Could not parse atlas index ${atlasIndexWithFreeSpace}, this should not happen`);
    }
    const freeTextureCoordinate = find(
      textureCoordinateOptions,
      ([x, y]) => !find(previousAssignmentsByTexture[atlasIndex], { x, y })
    );
    if (!freeTextureCoordinate) {
      throw new Error(`Could not find free texture coordinate for atlas ${atlasIndex}, this should not happen`);
    }
    return {
      atlasIndex,
      x: freeTextureCoordinate[0],
      y: freeTextureCoordinate[1],
    };
  } else {
    return undefined;
  }
}

export function getChannelAtlasAssignment({
  channelData,
  shouldLoadChannels,
  numChannels,
  previousAssignments,
  maxTextures,
  debug = false,
}: {
  channelData: MultiScaleImageData;
  numChannels: number;
  shouldLoadChannels: boolean[];
  previousAssignments?: Array<ChannelAtlasAssignment | null>;
  maxTextures: number;
  debug?: boolean;
}): Array<ChannelAtlasAssignment | null> {
  const channelAtlasAssignments: Array<ChannelAtlasAssignment | null> =
    previousAssignments || times(numChannels, () => null);

  forEach(channelData.data, (singleChannelData, channelIndex) => {
    const existingAssignment = channelAtlasAssignments[channelIndex];
    if (existingAssignment) {
      channelAtlasAssignments[channelIndex] = existingAssignment;
      return;
    }
    if (!singleChannelData?.data || !shouldLoadChannels[channelIndex]) {
      channelAtlasAssignments[channelIndex] = null;
      return;
    }

    const assignmentInExistingTexture = getExistingTextureFreeChannelAtlasAssignment(
      channelAtlasAssignments,
      maxTextures
    );

    if (assignmentInExistingTexture) {
      // If we get here, we have a texture atlas with free space
      if (debug) {
        console.debug(
          `Adding channel ${channelIndex} to an existing texture atlas at ${JSON.stringify(
            assignmentInExistingTexture
          )}`
        );
      }
      const previousAssignedChannelIndex = findIndex(channelAtlasAssignments, assignmentInExistingTexture);
      if (previousAssignedChannelIndex !== -1) {
        console.warn(`Found a channel assigned to ${assignmentInExistingTexture} - this should not happen`);
      }
      channelAtlasAssignments[channelIndex] = assignmentInExistingTexture;
      return;
    }

    // See if we can override channels that are not visible or have an opacity of 0
    const assignmentsForActiveChannels = map(channelAtlasAssignments, (assignment, channelIndexForAssignment) =>
      Boolean(assignment) && shouldLoadChannels[channelIndexForAssignment] ? assignment : null
    );

    const overwritingAssignment = getExistingTextureFreeChannelAtlasAssignment(
      assignmentsForActiveChannels,
      maxTextures
    );

    if (overwritingAssignment) {
      // If we get here, we can overwrite a channel that is not visible or has an opacity of 0
      if (debug) {
        console.debug(`Overwriting channel ${channelIndex} at ${JSON.stringify(overwritingAssignment)}`);
      }
      const previousAssignedChannelIndex = findIndex(channelAtlasAssignments, overwritingAssignment);
      if (previousAssignedChannelIndex === -1) {
        console.warn(`Could not find a channel assigned to ${overwritingAssignment} - this should not happen`);
      }
      channelAtlasAssignments[previousAssignedChannelIndex] = null;
      // Remove the assignment from the channel that was previously assigned to this texture coordinate
      channelAtlasAssignments[channelIndex] = overwritingAssignment;
      return;
    }

    if (debug) {
      console.debug(`Adding channel ${channelIndex} to a new texture atlas`);
    }

    const activeAssignmentsByTexture = groupBy(assignmentsForActiveChannels, 'atlasIndex');

    // If we get here, we need to add a new texture atlas
    const freeAtlasIndex = first(filter(times(maxTextures), (i) => !activeAssignmentsByTexture[i]));
    if (freeAtlasIndex === undefined || freeAtlasIndex === null) {
      console.error('Could not find free texture index, this should not happen', {
        activeAssignmentsByTexture,
        maxTextures,
        freeAtlasIndex,
      });
      throw new Error('Could not find free texture index, this should not happen');
    }
    channelAtlasAssignments[channelIndex] = { atlasIndex: freeAtlasIndex, x: 0, y: 0 };
  });
  if (debug) {
    console.debug('Channel atlas assignments', {
      channelData,
      shouldLoadChannels,
      numChannels,
      previousAssignments,
      maxTextures,
      channelAtlasAssignments,
    });
  }
  return channelAtlasAssignments;
}

export function isRectangularBounds(
  bounds: [number, number, number, number] | [Position, Position, Position, Position]
): bounds is [number, number, number, number] {
  return Number.isFinite(bounds[0]);
}
