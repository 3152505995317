import GL from '@luma.gl/constants';
import { Texture2D } from '@luma.gl/webgl';
import { DecodedPng } from 'fast-png';
import { forEach } from 'lodash';

import { MAX_CHANNELS } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/StainsLayers/constants';
import { ChannelAtlasAssignment } from './helpers';
import { rgbaToRedOnlyFloat32, rgbaToRedOnlyUint8 } from './utils';

export const populateChannelAtlasesWithImageData = ({
  channelAtlases,
  channelAssignments,
  perChannelData,
  redOnlyOnCPU,
  shouldLoadChannels,
  isChannelAssignmentUpToDate,
  numChannels,
  atlasChannelWidth,
  atlasChannelHeight,
  interpolation,
  isPng,
  debug,
}: {
  channelAtlases: Texture2D[];
  channelAssignments: Array<ChannelAtlasAssignment | null>;
  perChannelData: Array<ImageData | DecodedPng>;
  numChannels: number;
  redOnlyOnCPU?: boolean;
  shouldLoadChannels: boolean[];
  isChannelAssignmentUpToDate: boolean[];
  atlasChannelWidth: number;
  atlasChannelHeight: number;
  interpolation: number;
  isPng: boolean;
  debug?: boolean;
}) => {
  const maxChannels = Math.min(numChannels, MAX_CHANNELS);

  const isLinear = interpolation === GL.LINEAR;
  const channelsPerAtlas = Math.ceil(maxChannels / channelAtlases.length);
  const squareDim = Math.max(1, Math.ceil(Math.sqrt(channelsPerAtlas)));

  forEach(perChannelData, (imageFetchResponse, channelIndex) => {
    if (
      isChannelAssignmentUpToDate[channelIndex] ||
      !shouldLoadChannels[channelIndex] ||
      !channelAssignments[channelIndex]
    ) {
      if (debug) {
        console.debug(
          `Skipping channel ${channelIndex} because it's already loaded or not visible or has no assignment`,
          {
            isChannelAssignmentUpToDate,
            shouldLoadChannels,
            channelAssignments,
          }
        );
      }
      return;
    }
    if (channelIndex > maxChannels) {
      if (channelIndex > MAX_CHANNELS) {
        console.warn(
          `Too many channels for XRLayer.  Max channels is ${MAX_CHANNELS}. ` +
            `If more are needed - implement support for multiple texture atlases.`
        );
      } else {
        console.warn(
          `Configured max channels is ${maxChannels}, but was provided with ${perChannelData.length}. Skipping`
        );
      }
      return;
    }

    const assignment = channelAssignments[channelIndex];
    if (!assignment) {
      console.warn(`No assignment found for channel ${channelIndex}`, {
        channelAssignments,
        channelIndex,
      });
      return;
    }
    const { atlasIndex, x: xOffset, y: yOffset } = assignment;
    const channelsAtlas = channelAtlases[atlasIndex];
    if (!channelsAtlas) {
      console.warn(`No atlas found for channel ${channelIndex}`, {
        atlasIndex,
        channelAssignments,
        channelAtlasesNumber: channelAtlases.length,
        channelAtlases,
        shouldLoadChannels,
      });
      return;
    }
    if (xOffset >= squareDim || yOffset >= squareDim || xOffset < 0 || yOffset < 0) {
      console.warn(`Channel ${channelIndex} is out of bounds of atlas ${atlasIndex}`, {
        xOffset,
        yOffset,
        squareDim,
      });
      return;
    }
    const x = xOffset * atlasChannelWidth;
    const y = yOffset * atlasChannelHeight;
    const imageData =
      !isPng && redOnlyOnCPU
        ? isLinear
          ? rgbaToRedOnlyFloat32(imageFetchResponse.data as Uint8ClampedArray)
          : rgbaToRedOnlyUint8(imageFetchResponse.data)
        : isLinear
        ? new Float32Array(imageFetchResponse.data)
        : imageFetchResponse.data;

    if (debug) {
      console.debug('Loading channel', channelIndex, 'into atlas', atlasIndex, 'at', x, y);
    }
    channelsAtlas.setSubImageData({
      data: imageData,
      x,
      y,
      width: imageFetchResponse.width,
      height: imageFetchResponse.height,
    });
  });
};
