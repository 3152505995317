import { Tooltip } from '@mui/material';
import { ConditionalTextEditor } from 'components/atoms/DataGridRenderers/ConditionalTextEditor';
import { StainTypeDraft } from 'components/Pages/OperationsSettings/StainTypesDataGrid/types';
import { DisplayedField } from 'interfaces/genericFields';
import { StainType } from 'interfaces/stainType';
import { filter, flatMap, includes, map } from 'lodash';
import React from 'react';

const idRegex = /^[a-z\d/]+(?:[-_][a-z\d/]+)*$/;

export const idField: DisplayedField<StainType & { draftId?: string }, string> = {
  cellEditType: 'text',
  filterType: 'text',
  dataKey: 'id',
  label: 'Stain Type ID',
  columnWidth: { width: 200 },
  getError: ({ value }) =>
    !idRegex.test(value)
      ? 'Stain type id must be lowercase and contain only letters, numbers. ' +
        'Hyphens, and underscores are allowed as separators.'
      : undefined,
  customCellEditor:
    (field, context) =>
    ({ isEditable: isEditableParam, ...params }) => {
      const { value, row } = params;
      const isEditable = (stain: StainType & { draftId?: string }) => Boolean(isEditableParam && stain.draftId);
      const error = field.getError?.({ context, value, row });
      const showError = isEditable(row) && Boolean(error);
      return (
        <Tooltip open={Boolean(showError)} color="error" title={showError ? error : undefined}>
          <ConditionalTextEditor {...params} isEditable={isEditable} />
        </Tooltip>
      );
    },
};

export const displayNameField: DisplayedField<StainType, string> = {
  cellEditType: 'text',
  filterType: 'text', // TODO: change to multiSelect (based on search filter perhaps) or text search
  dataKey: 'displayName',
  label: 'Display Name',
  columnWidth: { width: 200 },
};

export const indexField: DisplayedField<StainType, number> = {
  filterType: 'multiSelect',
  dataKey: 'index',
  label: 'Index',
  columnWidth: { width: 150 },
};

export const canBeMifMarkerField: DisplayedField<StainType, boolean> = {
  cellEditType: 'checkbox',
  filterType: 'checkbox',
  dataKey: 'canBeMifMarker',
  label: 'Can be MIF Marker',
  columnWidth: { width: 150 },
};

export const aliasesField: DisplayedField<StainType, string[], { stainTypes: (StainType | StainTypeDraft)[] }> = {
  cellEditType: 'multiText',
  unwoundRowCellEditType: 'text',
  filterType: 'multiText',
  dataKey: 'aliases',
  label: 'Aliases',
  columnWidth: { width: 200 },
  getError: ({ value, row, context }) => {
    const stainTypesNotDeprecated = filter(context?.stainTypes, (stainType) => !stainType?.deletedAt);
    const stainIds = map(stainTypesNotDeprecated, 'id');
    const stainDisplayNames = map(stainTypesNotDeprecated, 'displayName');
    const stainAliases = flatMap(stainTypesNotDeprecated, 'aliases');
    for (const alias of value || []) {
      if (alias === row.id) return `Alias cannot be the same as the Stain Type ID`;
      if (alias === row.displayName) return `Alias cannot be the same as the Stain Type's Display Name`;
      if (includes(stainIds, alias)) return `Alias cannot be the same as another Stain Type ID`;
      if (includes(stainDisplayNames, alias)) return `Alias cannot be the same as another Stain Type's Display Name`;
      if (includes(stainAliases, alias)) return `Alias cannot be the same as another Stain Type's Alias`;
    }
  },
};

export const stainTypeFields: Array<DisplayedField<StainType, any>> = [
  idField,
  displayNameField,
  indexField,
  canBeMifMarkerField,
  aliasesField,
];
