import { QueryKey, useQuery } from '@tanstack/react-query';
import { getPendingSlidesCount, getSlides } from 'api/slides';
import { Permission } from 'interfaces/permissionOption';
import { Procedure } from 'interfaces/procedure';
import { Slide } from 'interfaces/slide';
import { map, omit, slice } from 'lodash';
import { stringify } from 'qs';
import { useMemo } from 'react';
import { BooleanParam, encodeQueryParams, useQueryParam } from 'use-query-params';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';
import { DEFAULT_PAGE_SIZE } from './ProcedurePagination';

export const usePendingSlides = ({ enabled }: { enabled?: boolean } = { enabled: false }) => {
  const { labId } = useCurrentLabId();
  const { queryParams, queryParamsSchema } = useEncodedFilters();
  const [pendingSlidesMode] = useQueryParam('pendingSlidesMode', BooleanParam);
  const { hasPermission } = usePermissions();
  const canViewPendingSlides: boolean = hasPermission(Permission.ViewUnpublishedResults);
  const page: number = queryParams.page || 1;
  const pageSize: number = queryParams.pageSize || DEFAULT_PAGE_SIZE;
  const encodedFilters = { filters: { ...omit(queryParams.filters, 'studyId') }, labId };
  const pendingSlidesQueryKey: QueryKey = ['pendingSlides', encodedFilters];

  const { data: pendingSlidesCount } = useQuery(
    ['pendingSlidesCount', encodedFilters],
    ({ signal }) => getPendingSlidesCount(stringify(encodeQueryParams(queryParamsSchema, encodedFilters)), signal),
    {
      enabled: enabled && canViewPendingSlides,
    }
  );

  const {
    data: pendingSlides,
    isLoading: isPendingSlidesLoading,
    error: pendingSlidesError,
    ...pendingSlidesQuery
  } = useQuery(
    ['slides', encodedFilters, { pending: true }],
    ({ signal }) =>
      getSlides(
        { queryParams: stringify(encodeQueryParams(queryParamsSchema, encodedFilters)), pending: true },
        signal
      ),
    {
      enabled: canViewPendingSlides && (Boolean(pendingSlidesMode) || enabled),
      retry: false,
    }
  );

  const allMockCasesOfPendingSlides = useMemo(() => {
    return createMockCasesForSlides(pendingSlides, labId);
  }, [pendingSlides, labId]);

  const mockCasesOfPendingSlides = useMemo(() => {
    return slice(allMockCasesOfPendingSlides, pageSize * (page - 1), pageSize * (page - 1) + pageSize);
  }, [allMockCasesOfPendingSlides, page, pageSize]);

  return {
    mockCasesOfPendingSlides,
    allMockCasesOfPendingSlides,
    allPendingSlide: pendingSlides,
    isPendingSlidesLoading,
    pendingSlidesError,
    totalPendingSlides: pendingSlidesCount || 0,
    pendingSlidesInPreviousPages: pageSize * (page - 1),
    pendingCasesInPreviousPages: pageSize * (page - 1),
    pendingSlidesQueryKey,
    pendingSlidesQuery,
  };
};

export const createMockCasesForSlides = (slides: Slide[], labId: string): Procedure[] => {
  return map(slides, (slide, index) => {
    return {
      id: index,
      labId: labId,
      name: `${slide.originalFileName}`,
      batchDate: undefined,
      createdAt: slide?.createdAt,
      diagnosis: undefined,
      label: `${slide.originalFileName}`,
      cancerTypeId: 9, // N/A
      cancerSubtypes: [],
      clinicalData: undefined,
      internallyApproved: undefined,
      features: undefined,
      studyId: undefined,
      slides: [slide],
    };
  });
};
