import { useQuery } from '@tanstack/react-query';
import { getChannelMarkers } from 'api/markerTypes';
import { StainType } from 'interfaces/stainType';
import { map } from 'lodash';
import { useMemo } from 'react';
import { JsonParam } from 'use-query-params';
import { encodeQueryParamsUsingSchema } from 'utils/useEncodedFilters';
import { useStainTypeIdToDisplayName } from 'utils/useStainTypeIdToDisplayName';

const useStudyChannelMarkerTypes = (
  studyId: string | undefined,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {}
) => {
  const studyChannelsQueryParams = encodeQueryParamsUsingSchema(
    {
      filters: {
        studyId,
      },
    },
    {
      filters: JsonParam,
    },
    { arrayFormat: 'repeat' }
  );

  const { data: channelMarkersResponse, isLoading: isCheckingStudyChannels } = useQuery({
    queryKey: ['multiplex_marker_types', studyChannelsQueryParams],
    queryFn: ({ signal }) => getChannelMarkers(studyChannelsQueryParams, signal),
    enabled,
  });

  const { stainTypeIdToDisplayName, isLoadingStainTypeOptions } = useStainTypeIdToDisplayName();

  const namedMarkerTypes: StainType[] = useMemo(() => {
    return map(channelMarkersResponse?.channelMarkerTypes, (markerType, index) => ({
      id: markerType,
      displayName: stainTypeIdToDisplayName(markerType),
      canBeMifMarker: true,
      index,
    }));
  }, [channelMarkersResponse, stainTypeIdToDisplayName]);

  return { channelMarkersResponse, isCheckingStudyChannels, isLoadingStainTypeOptions, markerTypes: namedMarkerTypes };
};

export default useStudyChannelMarkerTypes;
