import { Taxonomy, TaxonomyTree } from 'interfaces/taxonomy';
import { forEach, has } from 'lodash';

// Define a type for the internal structure of the tree during construction
interface TreeNode {
  _data?: Taxonomy | null;
  children: { [key: string]: TreeNode };
}

export function buildTaxonomyTree(taxonomies: Taxonomy[]): TaxonomyTree[] {
  // Initialize the root node as an empty object with typed TreeNode
  const root: { [key: string]: TreeNode } = {};

  // Helper function to insert a taxonomy into the tree
  const insert = (pathParts: string[], taxonomy: Taxonomy) => {
    let currentLevel: { [key: string]: TreeNode } = root;
    // the function traverses the tree, creating nodes as needed
    forEach(pathParts, (part, index) => {
      // If the current part does not exist in the current level, create it
      if (!currentLevel[part]) {
        currentLevel[part] = { _data: null, children: {} };
      }
      // If we are at the last part of the path, set the taxonomy data
      if (index === pathParts.length - 1) {
        currentLevel[part]._data = taxonomy;
      }
      currentLevel = currentLevel[part].children;
    });
  };

  // Populate the tree structure
  forEach(taxonomies, (taxonomy) => {
    const pathParts = taxonomy.path.split('.');
    insert(pathParts, taxonomy);
  });

  // Helper function to convert the tree into the desired nested format, recursively
  const convertToNestedFormat = (tree: { [key: string]: TreeNode }, path: string = ''): TaxonomyTree[] => {
    const nestedTree: TaxonomyTree[] = [];

    forEach(tree, (node, key) => {
      if (has(tree, key)) {
        const nodePath = path ? `${path}.${key}` : key;
        const taxonomyTreeNode: TaxonomyTree = {
          name: node._data?.name || key,
          path: nodePath,
          isAbstract: node._data?.isAbstract || false,
          exists: Boolean(node._data),
          children: convertToNestedFormat(node.children, nodePath),
        };
        nestedTree.push(taxonomyTreeNode);
      }
    });

    return nestedTree;
  };

  // Convert the root to the desired format
  return convertToNestedFormat(root);
}
