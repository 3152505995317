import { Button, Grid } from '@mui/material';
import { getSlideAnnotationsQueryKey, saveAnnotationDraft } from 'api/annotations';
import { useConfirmation } from 'components/modals/ConfirmationContext';
import { ShapeSubTypes } from 'components/Procedure/Header/SlideInteractionMenu';
import { viewerAnnotationData } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import {
  Feature as GeojsonFeature,
  FeatureCollection,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/NebulaGLExtensions/geojson-types';
import equal from 'deep-equal';
import { Annotation, Feature, GeoJSONPolygon, TodoDatum } from 'interfaces/annotation';
import { map, slice } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { StringParam, useQueryParam } from 'use-query-params';
import queryClient from 'utils/queryClient';
import { useMutationWithErrorSnackbar } from 'utils/useMutationWithErrorSnackbar';

export interface AnnotationChangesControlsProps {
  viewerIndex: number;
  slideId: string;
}

function removeDuplicateCoordinates(feature: GeojsonFeature): GeoJSONPolygon[] {
  const coordinates = feature.geometry.coordinates[0] as unknown as GeoJSONPolygon[];
  if (feature.properties.shapeSubType === ShapeSubTypes.Rect) {
    // in the db a rectangle annotation should have only 4 coordinates (in valid GeoJSON it should have 5)
    return slice(coordinates, 0, 4);
  }

  // this is a polygon
  let firstDupIndex = coordinates.length - 1;
  while (firstDupIndex > 0) {
    if (!equal(coordinates[firstDupIndex], coordinates[firstDupIndex - 1])) {
      firstDupIndex += 1;
      break;
    }
    firstDupIndex -= 1;
  }

  if (firstDupIndex < coordinates.length - 1) {
    return slice(coordinates, 0, firstDupIndex);
  }
  return coordinates;
}

/**
 * Adds hidden and selected properties to the feature properties
 * and removes duplicate coordinates from polygons and rectangles
 *
 * @param feature GeoJSON feature to convert
 * @returns the converted feature (or as we call it in the db - marker)
 */
const convertGeoJsonFeatureToMarker = (feature: GeojsonFeature) => {
  const properties = {
    ...feature.properties,
    hidden: false,
    selected: false,
  } as Feature['properties'];

  if (feature.properties.shapeSubType === ShapeSubTypes.Point) {
    return { ...feature, properties };
  }
  if (
    feature.properties.shapeSubType === ShapeSubTypes.Polygon ||
    feature.properties.shapeSubType === ShapeSubTypes.Rect
  ) {
    return {
      ...feature,
      properties,
      geometry: {
        ...feature.geometry,
        coordinates: removeDuplicateCoordinates(feature),
      },
    };
  }

  throw new Error(`Invalid feature shape sub type: ${feature.properties.shapeSubType}`);
};

function convertGeoJsonToInnerType(featureCollection: FeatureCollection): TodoDatum {
  // takes a proper GeoJSON featureCollection and converts it to the format used in the db (Semi-GeoJSON format)
  return {
    todo: 'merged-todo',
    markers: map(featureCollection.features, convertGeoJsonFeatureToMarker),
  };
}

export const AnnotationChangesControls: React.FC<React.PropsWithChildren<AnnotationChangesControlsProps>> = ({
  viewerIndex,
  slideId,
}) => {
  const [editAnnotationsMode, setEditAnnotationsMode] = useQueryParam('editAnnotationsMode', StringParam);

  const confirmWithModal = useConfirmation();

  const saveDraftMutation = useMutationWithErrorSnackbar({
    mutationFn: saveAnnotationDraft,
    mutationDescription: 'save annotation draft',
    onMutate: ({ annotationAssignmentId, slideId: updatedSlideId, annotationsData }) => {
      queryClient.setQueryData(
        getSlideAnnotationsQueryKey({ slideId: updatedSlideId, includeEmpty: true }),
        (oldData: Annotation[]) => {
          if (oldData) {
            return map(oldData, (annotation) => {
              if (annotation.annotationAssignment.annotationAssignmentId === annotationAssignmentId) {
                return {
                  ...annotation,
                  draftAnnotationsData: annotationsData,
                };
              }
              return annotation;
            });
          }
          return oldData;
        }
      );
    },
    onSuccess: () => {
      enqueueSnackbar('Annotation saved', { variant: 'success' });
      queryClient.invalidateQueries(getSlideAnnotationsQueryKey({ slideId: slideId, includeEmpty: true }));
    },
  });

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const saveDraft = async () => {
    if (
      await confirmWithModal({
        title: 'Save annotation draft',
        text: 'Are you sure you want to save the annotation?',
        confirmButtonProps: { title: 'Save' },
        cancelButtonProps: { title: 'Cancel' },
      })
    ) {
      saveDraftMutation.mutate({
        annotationAssignmentId: activeAnnotationAssignmentId,
        slideId,
        annotationsData: [convertGeoJsonToInnerType(viewerAnnotationData[viewerIndex].value)],
      });
      setEditAnnotationsMode(null);
    }
  };

  const cancelEdit = async () => {
    if (
      await confirmWithModal({
        title: 'Cancel annotation changes',
        text: 'Are you sure you want to cancel?',
        confirmButtonProps: { title: 'Cancel' },
        cancelButtonProps: { title: 'Continue' },
      })
    ) {
      setEditAnnotationsMode(null);
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = null;
      }
    }
  };

  return (
    <>
      {editAnnotationsMode && (
        <Grid item container justifyContent="end" py={2} spacing={1}>
          <Grid item>
            <Button variant="contained" color="primary" value="save" onClick={saveDraft}>
              Save as draft
            </Button>
          </Grid>
          <Grid item>
            <Button color="secondary" value="cancel" onClick={cancelEdit}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};
