import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';

export const getHeatmapUrlKeyFromFeatureMetadata = ({
  heatmap,
  parentHeatmap,
  addOrchestrationId, // Required for internal heatmaps
}: {
  heatmap: FeatureMetadata;
  parentHeatmap?: FeatureMetadata;
  addOrchestrationId?: boolean;
}) => {
  if (!heatmap) {
    return '';
  }
  const parentHeatmapUrlKey = parentHeatmap?.key || parentHeatmap?.id;
  const heatmapBaseUrlKey = heatmap.key || heatmap.id;
  const keyWithoutOrchId =
    parentHeatmapUrlKey && heatmap?.id !== parentHeatmap?.id
      ? `${parentHeatmapUrlKey}-${heatmapBaseUrlKey}`
      : heatmapBaseUrlKey;
  // Multiple internal heatmaps can have the same layer key, so we need to use the orchestration id to differentiate them
  return addOrchestrationId
    ? `${parentHeatmap?.orchestrationId || heatmap.orchestrationId}-${keyWithoutOrchId}`
    : keyWithoutOrchId;
};
