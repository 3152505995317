import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
} from '@mui/material';
import { TaxonomyTree } from 'interfaces/taxonomy';
import React from 'react';
import { useUpdateTaxonomyMutation } from 'utils/queryHooks/taxonomy/useTaxonomyMutations';

import { Checkbox, Grid, TextField } from '@mui/material';
import Loader from 'components/Loader';

import { isNull, omitBy } from 'lodash';

interface EditTaxonomyButtonProps {
  taxonomyTree: TaxonomyTree;
}

const EditTaxonomyButton: React.FC<EditTaxonomyButtonProps> = ({ taxonomyTree }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [isAbstract, setIsAbstract] = React.useState<boolean | null>(null);
  const [path, setPath] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string | null>(null);

  const updateTaxonomyModel = omitBy(
    {
      isAbstract,
      newPath: path,
      name,
    },
    isNull
  );

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const mutation = useUpdateTaxonomyMutation(taxonomyTree.path);

  return (
    <>
      <IconButton aria-label="Edit" onClick={() => handleOpenDialog()}>
        <EditIcon />
      </IconButton>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Edit Taxonomy</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormControl>
                <FormLabel>is abstract?</FormLabel>
                <Checkbox
                  checked={isAbstract || taxonomyTree.isAbstract}
                  onChange={(e) => setIsAbstract(e.target.checked)}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>Path</FormLabel>
                <TextField fullWidth value={path || taxonomyTree.path} onChange={(e) => setPath(e.target.value)} />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <TextField value={name || taxonomyTree.name} onChange={(e) => setName(e.target.value)} />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {mutation.isLoading ? (
            <Loader size="small" />
          ) : (
            <Button onClick={() => mutation.mutate(updateTaxonomyModel)}>Update</Button>
          )}
          <Button onClick={handleCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTaxonomyButton;
