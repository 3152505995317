import { Skeleton, Typography } from '@mui/material';
import { Procedure } from 'interfaces/procedure';
import { map, sum } from 'lodash';
import React from 'react';
import { ExperimentResultsSelection, useEncodedFilters } from 'utils/useEncodedFilters';
import { DEFAULT_PAGE_SIZE } from './ProcedurePagination';

export const PageStats: React.FC<
  React.PropsWithChildren<{
    totalCases: number;
    totalSlides: number;
    slidesInPreviousPages: number;
    proceduresInPreviousPages: number;
    pageCases?: Procedure[];
    casesLoading?: boolean;
  }>
> = ({ totalCases, totalSlides, slidesInPreviousPages, proceduresInPreviousPages, pageCases, casesLoading }) => {
  const { queryParams } = useEncodedFilters({
    experimentResultsSelection: ExperimentResultsSelection.OnlyQAFailed,
  });

  const page = queryParams.page || 1;
  const pageSize = queryParams.pageSize || DEFAULT_PAGE_SIZE;

  let casesRangeEnd = 0;
  let casesRangeStart = 0;
  let slidesRangeEnd = 0;
  let slidesRangeStart = 0;
  let casesInPage = 0;
  let slidesInPage = 0;

  if (queryParams?.slidesMode) {
    slidesInPage = pageSize;
    slidesRangeStart = totalSlides > 0 ? pageSize * (page - 1) + 1 : 0;
    slidesRangeEnd = slidesRangeStart + slidesInPage >= totalSlides ? totalSlides : slidesRangeStart + slidesInPage - 1;

    casesInPage = pageCases?.length ?? 0;
    casesRangeStart =
      totalCases > 0 ? (proceduresInPreviousPages + 1 > totalCases ? totalCases : proceduresInPreviousPages + 1) : 0;
    casesRangeEnd = casesRangeStart + casesInPage >= totalCases ? totalCases : casesRangeStart + casesInPage - 1;
  } else {
    casesInPage = pageSize;
    casesRangeStart = totalCases > 0 ? pageSize * (page - 1) + 1 : 0;
    casesRangeEnd = casesRangeStart + casesInPage >= totalCases ? totalCases : casesRangeStart + casesInPage - 1;

    slidesInPage = sum(map(pageCases, (caseData) => caseData?.slides?.length ?? 0));
    slidesRangeStart =
      totalSlides > 0 ? (slidesInPreviousPages + 1 > totalSlides ? totalSlides : slidesInPreviousPages + 1) : 0;
    slidesRangeEnd = slidesRangeStart + slidesInPage >= totalSlides ? totalSlides : slidesRangeStart + slidesInPage - 1;
  }

  const slidesCountTitle = !isNaN(Number(slidesInPreviousPages))
    ? `${slidesRangeStart} - ${slidesRangeEnd} slides of ${totalSlides} slides`
    : `${slidesInPage} slides of ${totalSlides} slides`;

  const casesCountTitle = !isNaN(Number(proceduresInPreviousPages))
    ? `${casesRangeStart} - ${casesRangeEnd} cases of ${totalCases} cases`
    : `${casesInPage} cases of ${totalCases} cases`;

  const baseTitle = queryParams?.slidesMode ? slidesCountTitle : casesCountTitle;
  const secondaryTitle = queryParams?.slidesMode ? casesCountTitle : slidesCountTitle;

  return (
    <>
      <Typography variant="body2">
        {casesLoading ? <Skeleton variant="text" animation="wave" width={120} sx={{ fontSize: '1.2em' }} /> : baseTitle}
      </Typography>
      <Typography variant="caption">
        {casesLoading ? <Skeleton variant="text" animation="wave" width={80} /> : secondaryTitle}
      </Typography>
    </>
  );
};
