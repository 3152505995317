import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTaxonomy, deleteTaxonomy, updateTaxonomy } from 'api/taxonomy';
import { Taxonomy, TaxonomyCreate, UpdateTaxonomy } from 'interfaces/taxonomy';
import { reject } from 'lodash';
import { taxonomyQueryKey } from './useTaxonomy';

export const useCreateTaxonomyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taxonomy: TaxonomyCreate) => createTaxonomy(taxonomy),
    onSuccess: (returnedModel) => {
      queryClient.invalidateQueries(taxonomyQueryKey);

      queryClient.setQueryData(taxonomyQueryKey, (oldData: Taxonomy[]) => {
        return [...oldData, returnedModel];
      });
    },
  });
};

export const useUpdateTaxonomyMutation = (path: string) => {
  const queryClient = useQueryClient();
  return useMutation((taxonomyUpdate: UpdateTaxonomy) => updateTaxonomy(path, taxonomyUpdate), {
    onSuccess: (returnedModel) => {
      queryClient.invalidateQueries(taxonomyQueryKey);

      queryClient.setQueryData(taxonomyQueryKey, (oldData: Taxonomy[]) => {
        // filter out the old taxonomy object
        const newTaxonomies = reject(oldData, { path });

        // add the updated taxonomy object
        newTaxonomies.push(returnedModel);

        return newTaxonomies;
      });
    },
  });
};

export const useDeleteTaxonomyMutation = (path: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteTaxonomy(path), {
    onSuccess: () => {
      queryClient.invalidateQueries(taxonomyQueryKey);

      queryClient.setQueryData(taxonomyQueryKey, (oldData: Taxonomy[]) => {
        return reject(oldData, (taxonomy) => taxonomy.path === path);
      });
    },
  });
};
