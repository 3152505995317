import { Autocomplete, Grid, TextField, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getTodoTypes } from 'api/annotationAssignments';
import { AnnotationAssignmentAutocomplete } from 'components/atoms/AnnotationAssignmentAutocomplete';
import TreeCheckboxMultiSelect from 'components/atoms/TreeCheckboxSelect';
import DashboardTabs from 'components/DashboardTabs';
import { TodoOption, TodoType } from 'interfaces/annotation';
import { MULTIPLEX_STAIN_ID } from 'interfaces/stainType';
import { compact, filter, find, flatMap, includes, indexOf, map, uniq } from 'lodash';
import React, { useMemo, useState } from 'react';
import useTaxonomy from 'utils/queryHooks/taxonomy/useTaxonomy';
import { CasesParams } from 'utils/useCasesParams';
import { useCurrentLabId } from 'utils/useCurrentLab';
import ClassesToAnnotateList from './ClassesToAnnotateList';

interface ClassesToAnnotateSelectionProps {
  selectedClassesToAnnotate: TodoOption[];
  onClassesSelected: (classes: TodoOption[]) => void;
}

const ClassesToAnnotateSelection: React.FC<ClassesToAnnotateSelectionProps> = ({
  selectedClassesToAnnotate,
  onClassesSelected,
}) => {
  const { labId } = useCurrentLabId();
  const theme = useTheme();
  const tabs = ['Preset', 'Taxonomy', 'Assignment'];
  const [activeTab, setActiveTab] = useState<string>('Preset');
  const activeTabIndex = indexOf(tabs, activeTab) !== -1 ? indexOf(tabs, activeTab) : 0;

  const [assignmentId, setAssignmentId] = useState<number | null>(null);
  const [assignmentTodoOptions, setAssignmentTodoOptions] = useState<TodoOption[]>([]);
  const selectedClassesToAnnotateNames = useMemo(
    () => compact(map(selectedClassesToAnnotate, 'name')) ?? [],
    [selectedClassesToAnnotate]
  );

  const {
    data: todoTypes,
    isLoading: isLoadingTodoTypes,
    error: todoTypesError,
  } = useQuery(['todoTypes'], getTodoTypes);
  const todoTypeOptions: TodoOption[] = useMemo(() => {
    return flatMap(todoTypes, 'options');
  }, [todoTypes]);
  const [selectedTodoType, setSelectedTodoType] = useState<TodoType | null>(null);
  const [selectedTodoTypeOptions, setSelectedTodoTypeOptions] = useState<TodoOption[]>([]);

  const { data: taxonomies } = useTaxonomy();

  const changeTab = (newTab: string) => {
    setActiveTab(newTab);
  };

  const casesParams: CasesParams = {
    labId,
  };

  const handleTaxonomiesChange = (taxonomyIds: string[]) => {
    const newClassesToAnnotate: TodoOption[] = map(taxonomyIds, (taxonomyId) => {
      const previousClassToAnnotate = find(selectedClassesToAnnotate, { name: taxonomyId });
      if (previousClassToAnnotate) {
        return previousClassToAnnotate;
      }
      // after adding the type to the taxonomy table we should take the type from there
      const inferredClassName: 'polygon' | 'cell' =
        taxonomyId.includes('cell') && !taxonomyId.includes('segmentation') ? 'cell' : 'polygon';
      // search the name in todoTypes to get the color and className
      const todoType = find(todoTypeOptions, (todoTypeOption) => todoTypeOption.name === taxonomyId);
      return {
        name: taxonomyId,
        color: todoType?.color ?? theme.palette.primary.main,
        className: todoType?.className ?? inferredClassName,
        displayName: find(taxonomies, (taxonomy) => taxonomy.path === taxonomyId)?.name || taxonomyId,
      };
    });
    onClassesSelected(newClassesToAnnotate);
  };

  const border = `1px solid ${theme.palette.mode == 'light' ? 'lightgrey' : 'rgb(81, 81, 81)'}`;

  return (
    <Grid
      item
      xs={6}
      container
      direction="column"
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
        borderRadius: 0,
        borderLeft: border,
      }}
      height="100%"
    >
      <Grid
        item
        sx={{
          px: 1,
          borderRadius: 0,
          borderBottom: border,
        }}
      >
        <DashboardTabs tabsDisplayTexts={tabs} tabs={tabs} changeTab={changeTab} defaultValueIndex={activeTabIndex} />
      </Grid>
      <Grid item xs={true} container width="100%">
        {activeTab === 'Preset' && (
          <Grid item container direction="column" mt={2}>
            <Grid item px={2}>
              {todoTypesError && <Typography color="error">Failed to fetch presets</Typography>}
              <Autocomplete
                options={todoTypes || []}
                getOptionLabel={(option) => option.todo}
                value={selectedTodoType}
                onChange={(_, newValue) => {
                  setSelectedTodoType(newValue);
                  setSelectedTodoTypeOptions(newValue?.options);
                }}
                renderInput={(params) => <TextField {...params} label="Select Preset" />}
                loading={isLoadingTodoTypes}
              />
            </Grid>
            <Grid item xs={true} sx={{ overflow: 'auto' }}>
              <ClassesToAnnotateList
                classesToAnnotate={selectedTodoTypeOptions}
                isSelectable
                isTodoSelected={(id) => includes(selectedClassesToAnnotateNames, id)}
                toggleSelection={(id) =>
                  onClassesSelected(
                    includes(selectedClassesToAnnotateNames, id)
                      ? filter(selectedClassesToAnnotate, (classToAnnotate) => classToAnnotate.name !== id)
                      : [...(selectedClassesToAnnotate || []), find(selectedTodoTypeOptions, { name: id })]
                  )
                }
              />
            </Grid>
          </Grid>
        )}
        {activeTab === 'Taxonomy' && (
          <TreeCheckboxMultiSelect
            selectedTaxonomyIds={selectedClassesToAnnotateNames}
            onSelectTaxonomyIds={handleTaxonomiesChange}
          />
        )}
        {activeTab === 'Assignment' && (
          <Grid item container direction="column" mt={2}>
            <Grid item px={2}>
              <AnnotationAssignmentAutocomplete
                casesParams={casesParams}
                slideStainType={MULTIPLEX_STAIN_ID}
                selectedValue={assignmentId ?? null}
                onChange={(event, newValue) => {
                  setAssignmentId(newValue?.annotationAssignmentId);
                  setAssignmentTodoOptions(uniq(compact(flatMap(newValue?.todos, 'options'))));
                }}
                disableCloseOnSelect
                renderInput={(params) => <TextField {...params} label="Select Assignment" />}
              />
            </Grid>
            <Grid item xs={true} sx={{ overflow: 'auto' }}>
              <ClassesToAnnotateList
                classesToAnnotate={assignmentTodoOptions}
                isSelectable
                isTodoSelected={(id) => includes(selectedClassesToAnnotateNames, id)}
                toggleSelection={(id) =>
                  onClassesSelected(
                    includes(selectedClassesToAnnotateNames, id)
                      ? filter(selectedClassesToAnnotate, (classToAnnotate) => classToAnnotate.name !== id)
                      : [...(selectedClassesToAnnotate || []), find(assignmentTodoOptions, { name: id })]
                  )
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ClassesToAnnotateSelection;
