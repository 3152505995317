import { MenuItem, Tooltip } from '@mui/material';
import { NON_SPECIFIC_STUDY_VALUES } from 'components/SearchFilters/hooks/useMainFilters';
import { includes, toString } from 'lodash';
import React from 'react';
import { useEncodedFilters } from 'utils/useEncodedFilters';

export interface ActionMenuItemProps {
  disabled: boolean;
  header: string;
  onClick: () => void;
}

export const ActionMenuItem: React.FC<React.PropsWithChildren<ActionMenuItemProps>> = ({
  header,
  disabled,
  onClick,
}) => {
  const { queryParams } = useEncodedFilters();
  const isSelectedStudy =
    queryParams.filters?.studyId && !includes(NON_SPECIFIC_STUDY_VALUES, toString(queryParams.filters.studyId));

  // The span is needed to make the tooltip work when the button is disabled
  return (
    <Tooltip title={!isSelectedStudy ? 'Select Study' : disabled && 'Select procedures/slides'} placement="top" arrow>
      <span>
        <MenuItem onClick={onClick} disabled={!isSelectedStudy || disabled} data-cy={`action-menu-item-${header}`}>
          {header}
        </MenuItem>
      </span>
    </Tooltip>
  );
};
