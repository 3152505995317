import { COORDINATE_SYSTEM, Layer, PickingInfo } from '@deck.gl/core/typed';
import { DeckGLProps } from '@deck.gl/react/typed';
import GL from '@luma.gl/constants';
import { useSignals } from '@preact/signals-react/runtime';
import { Feature } from '@turf/helpers';
import { cloneDeep, find, fromPairs, get, isArray, map, times, without } from 'lodash';
import { EditableGeoJsonLayer, ViewMode } from 'nebula.gl';
import { useCallback, useMemo } from 'react';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { editTypeToShapeSubTypeMap, SlideAnnotationEditType } from 'components/Procedure/Header/SlideInteractionMenu';
import {
  getAnnotationSettingsKey,
  getAnnotationTodoNameAndDefinition,
} from 'components/Procedure/Infobar/SlideInfobar/SlideAnnotation/helpers';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  useActiveAnnotationDraft,
  viewerAnnotationData,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/EditAnnotationLayers/useActiveAnnotationDraft';
import { Permission } from 'interfaces/permissionOption';
import { useActiveAnnotationAssignmentForViewer } from 'services/annotations/useAnnotationQueryParams';
import { hexToRgb } from 'utils/helpers';
import { usePermissions } from 'utils/usePermissions';
import { OrthographicMapViewState } from '../../OrthographicMapview';
import { DrawPolygonMode } from '../NebulaGLExtensions/DrawPolygonMode';
import {
  AnnotationPointMode,
  AnnotationPolygonByDraggingMode,
  AnnotationRectangleMode,
} from '../NebulaGLExtensions/editModes';
import { FeatureCollection } from '../NebulaGLExtensions/geojson-types';
import {
  getEditHandleColor,
  getEditHandleTypeFromEitherLayer,
  RGBAColor,
  TOOLTIP_COLOR,
} from '../NebulaGLExtensions/helpers';
import { ModifyOrthographicMode } from '../NebulaGLExtensions/ModifyOrthographicMode';
import { useSlideAnnotationLayersData } from '../useSlideAnnotationLayersData';

const doesFeatureMatchEditAnnotationsMode = (feature: Feature, editAnnotationsMode: string) => {
  if (editAnnotationsMode === 'point') {
    return feature?.geometry?.type === 'Point';
  } else if (editAnnotationsMode === 'polygon') {
    return feature?.properties?.shapeSubType === 'polygon';
  } else if (editAnnotationsMode === 'roi') {
    return feature?.properties?.shapeSubType === 'rect';
  }
  return true;
};

export const useEditAnnotationLayers = ({
  slideId,
  viewerIndex,
  viewState,
  viewSize,
  viewOnly,
}: {
  slideId: string;
  viewerIndex: number;
  viewState: OrthographicMapViewState;
  viewSize: { width: number; height: number };
  viewOnly?: boolean;
}) => {
  useSignals();
  const { hasPermission } = usePermissions();
  const canAnnotateSlides = hasPermission(Permission.AnnotateSlides);

  const { slideAnnotations } = useSlideAnnotationLayersData({ slideId });

  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
  const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

  const [activeAnnotationAssignmentId] = useActiveAnnotationAssignmentForViewer(viewerIndex);

  const savedAnnotationData = canAnnotateSlides
    ? find(slideAnnotations, { annotationAssignment: { annotationAssignmentId: activeAnnotationAssignmentId } })
    : undefined;
  const { todo } = getAnnotationTodoNameAndDefinition(savedAnnotationData);

  const visualSettingsForAnnotation = fromPairs(
    map(todo?.options, (option) => {
      const annotationSettingsKey = getAnnotationSettingsKey(savedAnnotationData, option?.name);
      return [annotationSettingsKey, slideLayerVisualizationSettings?.[annotationSettingsKey]?.value];
    })
  );

  const getSettingsForFeature = useCallback(
    (feature: Feature) => {
      const annotationId = feature?.properties?.annotationId;
      const diagnosis = feature?.properties?.diagnosis;
      const annotation = find(slideAnnotations, { annotationId });

      const annotationSettingsKey = annotation ? getAnnotationSettingsKey(annotation, diagnosis) : undefined;
      const featureSettings = annotationSettingsKey ? visualSettingsForAnnotation?.[annotationSettingsKey] : undefined;
      const opacity =
        featureSettings?.selected && featureSettings?.show ? ((featureSettings?.opacity || 100) / 100) * 255 : 0;

      let color = featureSettings?.color;

      if (!color) {
        const annotationTodo = getAnnotationTodoNameAndDefinition(annotation);

        // Use the color from the todo option if it exists, otherwise look in all todos
        const todoOptions = annotationTodo?.todo?.options;
        const matchingOption = find(todoOptions, { name: diagnosis });
        color = matchingOption?.color;
      }
      return { ...featureSettings, opacity, color };
    },
    [slideAnnotations, JSON.stringify(visualSettingsForAnnotation)]
  );

  const [hideFillColor] = useQueryParam('hideFillColor', BooleanParam);
  const [editAnnotationsModeFromUrl] = useQueryParam('editAnnotationsMode', StringParam);
  const editAnnotationsMode = viewOnly ? undefined : editAnnotationsModeFromUrl;

  const { activeAnnotationData: editAnnotationFeatureCollection } = useActiveAnnotationDraft({
    slideId,
    viewerIndex,
  });

  const getColorForFeature = useCallback(
    (feature: Feature, baseOpacity: number = 100): RGBAColor => {
      const matchingVisualizationSetting = getSettingsForFeature(feature);
      const opacity = (matchingVisualizationSetting?.opacity || 100) * baseOpacity;

      return matchingVisualizationSetting.color
        ? [
            ...hexToRgb(
              typeof matchingVisualizationSetting.color === 'string'
                ? matchingVisualizationSetting.color
                : matchingVisualizationSetting.color?.hex
            ),
            opacity,
          ]
        : [180, 180, 180, opacity];
    },
    [getSettingsForFeature]
  );

  const getFillColorForFeature = useCallback(
    (feature: Feature) => getColorForFeature(feature, 0.5),
    [getColorForFeature]
  );

  const deleteFeature: DeckGLProps['onClick'] = useCallback(
    (pickingInfo) => {
      const featureIndex = pickingInfo?.index;
      if (
        !editAnnotationFeatureCollection ||
        featureIndex === undefined ||
        !isArray(editAnnotationFeatureCollection.features) ||
        !editAnnotationFeatureCollection.features[featureIndex]
      ) {
        console.warn('No feature to delete', { editAnnotationFeatureCollection, featureIndex });
        return;
      }
      const updatedData = cloneDeep(editAnnotationFeatureCollection);
      updatedData.features.splice(featureIndex, 1);
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = updatedData;
      }
    },
    [viewerIndex, editAnnotationFeatureCollection]
  );

  const onClick = useCallback(
    (info: PickingInfo, event: Parameters<DeckGLProps['onClick']>[1]) => {
      if (editAnnotationsMode && event.rightButton) {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }
      if (editAnnotationsMode === SlideAnnotationEditType.Delete) {
        // @ts-ignore
        deleteFeature(info, event);
      }
    },
    [editAnnotationsMode, deleteFeature]
  );

  const editAnnotationFeaturesNumber = editAnnotationFeatureCollection?.features?.length ?? 0;
  const onEdit = useCallback(
    ({ updatedData }: { updatedData: FeatureCollection; editType: string; editContext: any }) => {
      times(updatedData?.features?.length ?? 0, (featureIndex) => {
        const { name } = getAnnotationTodoNameAndDefinition(savedAnnotationData);
        updatedData.features[featureIndex].properties.todo = name;
        updatedData.features[featureIndex].properties.shapeSubType =
          updatedData.features[featureIndex].properties?.shapeSubType ||
          get(editTypeToShapeSubTypeMap, editAnnotationsMode, '');
        updatedData.features[featureIndex].properties.annotationId = savedAnnotationData?.annotationId;
        updatedData.features[featureIndex].properties.annotationAssignmentId =
          savedAnnotationData?.annotationAssignment?.annotationAssignmentId;
        updatedData.features[featureIndex].properties.markerType = 'tagger_annotation';
      });
      if (viewerAnnotationData[viewerIndex]) {
        viewerAnnotationData[viewerIndex].value = updatedData;
      }
    },
    [savedAnnotationData, viewerIndex, editAnnotationsMode]
  );

  const selectedFeatureIndexes = useMemo(
    () => times(editAnnotationFeaturesNumber, (i) => i),
    [editAnnotationFeaturesNumber]
  );

  const EditableGeoJsonLayerMode =
    editAnnotationsMode === SlideAnnotationEditType.Polygon
      ? DrawPolygonMode
      : editAnnotationsMode === SlideAnnotationEditType.PolygonByDragging
      ? AnnotationPolygonByDraggingMode
      : editAnnotationsMode === SlideAnnotationEditType.Roi
      ? AnnotationRectangleMode
      : editAnnotationsMode === SlideAnnotationEditType.Point
      ? AnnotationPointMode
      : editAnnotationsMode === SlideAnnotationEditType.Modify
      ? ModifyOrthographicMode
      : ViewMode;

  const editAnnotationLayer = !activeAnnotationAssignmentId
    ? null
    : // @ts-ignore
      (new EditableGeoJsonLayer({
        id: `annotation-editable-${slideId}-${savedAnnotationData?.annotationId || 'new'}`,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        getRadius: 2,
        data: editAnnotationFeatureCollection,
        selectedFeatureIndexes,
        // @ts-ignore
        onClick,
        onEdit,
        mode: EditableGeoJsonLayerMode,
        modeConfig: {
          viewport: { ...viewState, ...viewSize },
          selectedIndexes: without(
            map(editAnnotationFeatureCollection?.features, (feature, index) =>
              doesFeatureMatchEditAnnotationsMode(feature as Feature, editAnnotationsMode) ? index : null
            ),
            null
          ),
          ...(editAnnotationsMode ? { lockRectangles: true } : {}),
        },
        pickable: true,
        autoHighlight: editAnnotationsMode === SlideAnnotationEditType.Delete,
        highlightColor: [255, 255, 255, 25],
        stroked: true,
        filled: !hideFillColor,
        editHandleType: 'point',
        getFillColor: getFillColorForFeature,
        getLineColor: getColorForFeature,
        _subLayerProps: {
          tooltips: { getColor: TOOLTIP_COLOR },
          geojson: {
            updateTriggers: {
              getFillColor: [getFillColorForFeature],
              getLineColor: [getColorForFeature],
            },
          },
        },
        getEditHandleIcon: getEditHandleTypeFromEitherLayer,
        getEditHandleIconSize: 40,
        getEditHandleIconColor: getEditHandleColor,

        pointRadiusMinPixels: 5,
        pointRadiusScale: 2,
        lineWidthScale: 2,
        lineWidthMinPixels: 2,

        parameters: {
          depthTest: true,
          depthMask: false,

          blend: true,
          blendEquation: GL.FUNC_ADD,
          blendFunc: [GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA],
        },
        updateTriggers: {
          renderSublayers: [editAnnotationsMode],
        },
      }) as Layer<any>);

  if (editAnnotationLayer && editAnnotationsMode === SlideAnnotationEditType.Delete) {
    // @ts-ignore
    editAnnotationLayer.getCursor = ({ isHovering }: { isHovering: boolean; isDragging: boolean }) => {
      if (isHovering) {
        return 'crosshair';
      } else {
        return 'pointer';
      }
    };
  }

  return editAnnotationLayer;
};
