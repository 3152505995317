import equal from 'deep-equal';
import { Feature, GeoJSONPolygon, TodoDatum } from 'interfaces/annotation';
import { cloneDeep, every, first, forEach, isEmpty, map, min, slice } from 'lodash';
import { uuidv4 } from 'utils/helpers';

// import OBJECT_HANDLER_TYPES from '../../constants/fabricObjectTypes';
export const OBJECT_HANDLER_TYPES = {
  SQUARE: 'rect',
  POINT: 'circle',
  POLYGON: 'polygon',
  RULER: 'ruler',
  POLYLINE: 'polyline', // deprecated, for backwards compatibility
  FREEFORM_POLYGON: 'freeform_polygon', // deprecated, for backwards compatibility
  FREEFORM_POLYLINE: 'freeform_polyline ', // deprecated, for backwards compatibility
  DRAG: 'drag',
  SELECT: 'select',
  NONE: '',
};

export interface InnerTodo {
  markers: Markers;
  todo: string;
}

export interface Markers {
  type: string;
  features: Feature[];
}

function isValid(todo: InnerTodo): boolean {
  return every(todo.markers.features, (feature) => Boolean(feature?.properties?.diagnosis));
}

function serialize(todo: InnerTodo): { markers: Feature[] } {
  // takes a proper GeoJSON featureCollection and converts it to the format used in the db (Semi-GeoJSON format)
  return {
    markers: map(todo.markers.features, (feature) => {
      const properties = {
        ...feature.properties,
        hidden: false,
        selected: false,
        strokeDashType: undefined as string,
      };
      if (feature.properties.shapeSubType === OBJECT_HANDLER_TYPES.POINT) {
        return { ...feature, properties };
      }
      if (
        feature.properties.shapeSubType === OBJECT_HANDLER_TYPES.POLYGON ||
        feature.properties.shapeSubType === OBJECT_HANDLER_TYPES.POLYLINE
      ) {
        return {
          ...feature,
          properties: {
            ...properties,
            shapeSubType: OBJECT_HANDLER_TYPES.POLYGON, // this will remove deprecated polyline from db
          },
          geometry: {
            ...feature.geometry,
            coordinates: removeDuplicateCoordinates(feature),
          },
        };
      }
      if (feature.properties.shapeSubType === OBJECT_HANDLER_TYPES.SQUARE) {
        return {
          ...feature,
          properties,
          geometry: {
            ...feature.geometry,
            coordinates: removeDuplicateCoordinates(feature),
          },
        };
      }

      throw new Error(`Invalid feature shape sub type: ${feature.properties.shapeSubType}`);
    }),
  };
}

function removeDuplicateCoordinates(feature: Feature): GeoJSONPolygon[] {
  const coordinates = first(feature?.geometry?.coordinates) as unknown as GeoJSONPolygon[];
  if (feature?.properties?.shapeSubType === OBJECT_HANDLER_TYPES.SQUARE) {
    // in the db a rectangle annotation should have only 4 coordinates (in valid GeoJSON it should have 5)
    return slice(coordinates, 0, 4);
  }

  // this is a polygon
  let firstDupIndex = coordinates.length - 1;
  while (firstDupIndex > 0) {
    if (!equal(coordinates[firstDupIndex], coordinates[firstDupIndex - 1])) {
      firstDupIndex += 1;
      break;
    }
    firstDupIndex -= 1;
  }

  if (firstDupIndex < coordinates.length - 1) {
    return slice(coordinates, 0, firstDupIndex);
  }
  return coordinates;
}

function compareFeatures(feature1: Feature, feature2: Feature) {
  /*
    Sort function for deserialize.
    We want hover to select the innermost feature at a given location.
    The selected feature will be the one that appears earlier in the features list and thus rendered first.
    All point features will be at the begining of the list, the rest are sorted by minimal y coordiante.
  */
  if (feature1?.geometry?.type === 'Point' && feature2?.geometry?.type !== 'Point') {
    return 1;
  }
  if (feature2?.geometry?.type === 'Point' && feature1?.geometry?.type !== 'Point') {
    return -1;
  }
  if (feature1?.geometry?.type === 'Point' && feature2?.geometry?.type === 'Point') {
    return 0;
  }

  const feature1Polygon = first(feature1?.geometry?.coordinates) as GeoJSONPolygon;
  const feature2Polygon = first(feature2?.geometry?.coordinates) as GeoJSONPolygon;
  return (
    min(map(feature1Polygon, (point: number[]) => point?.[1])) -
    min(map(feature2Polygon, (point: number[]) => point?.[1]))
  );
}

function deserialize(todo: TodoDatum, sortFeatures = false): InnerTodo {
  // deserializes from server response (Semi-GeoJSON) to proper GeoJSON format
  const featureCollection = { type: 'FeatureCollection', features: todo?.markers || [] };
  forEach(featureCollection.features, (feature) => {
    const { properties } = feature;
    if (!properties?.markerType) {
      properties.markerType = 'tagger_annotation';
    }
    if (!properties?.id) {
      properties.id = uuidv4();
    }
    if (feature.geometry.type === 'Polygon') {
      if (!isEmpty(feature.geometry.coordinates) && feature.geometry.coordinates.length > 1) {
        const coordinates = cloneDeep(feature.geometry.coordinates) as unknown as GeoJSONPolygon;

        const firstCoordinate = first(coordinates);
        if (!equal(firstCoordinate, coordinates[coordinates.length - 1])) {
          coordinates.push(firstCoordinate);
        }
        feature.geometry.coordinates = [coordinates]; // to conform with GeoJSON, polygons needs to have 3 arrays
      }
    }
  });
  if (sortFeatures) {
    featureCollection.features.sort(compareFeatures);
  }

  return {
    markers: featureCollection,
    todo: todo.todo,
  };
}

const markerAnnotationService = {
  isValid,
  serialize,
  deserialize,
};

export default markerAnnotationService;
