import { useSignals } from '@preact/signals-react/runtime';
import { find, values } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useTransition } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

import {
  baseSlidesVisualSettings,
  BaseSlideVisualSettings,
  defaultBaseSlideVisualSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import ToggleableSlider from 'components/Procedure/ToggleableSlider/ToggleableSlider';

const applyChangesToBaseSlideViewSettings = ({
  changes,
  slideId,
  viewerIndex,
}: {
  changes: Partial<BaseSlideVisualSettings>;
  slideId: string;
  viewerIndex: number;
}): BaseSlideVisualSettings => {
  const viewerBaseSlideSettingsInner = baseSlidesVisualSettings[viewerIndex];

  const previousViewerSettings = viewerBaseSlideSettingsInner.value;

  const newViewSettingsWithPrevious = {
    ...defaultBaseSlideVisualSettings,
    ...(previousViewerSettings?.[slideId] || {}),
    ...changes,
  };

  viewerBaseSlideSettingsInner.value = {
    ...previousViewerSettings,
    [slideId]: newViewSettingsWithPrevious,
  };

  return newViewSettingsWithPrevious;
};

export const BaseSlideControl: FC<{
  slideId: string;
  viewerIndex: number;
  stainTypeId: string;
}> = (props) => {
  useSignals();

  const { slideId, viewerIndex } = props;

  // In the URL, we store the settings for each stain type and viewer index to allow for sharing the settings when navigating to a different slide,
  // as well as to allow for sharing the settings when users share the URL.
  const [urlStainToBaseSlideSettings, setUrlStainToBaseSlideSettings] = useQueryParam<{
    [stainTypeId: string]: { [viewerIndex: number]: BaseSlideVisualSettings };
  }>('stainToBaseSlideSettings', JsonParam);

  const viewerBaseSlideSettings = baseSlidesVisualSettings[viewerIndex];

  const settingsFromUrl: BaseSlideVisualSettings = useMemo(
    () =>
      props.stainTypeId
        ? urlStainToBaseSlideSettings?.[props.stainTypeId]?.[viewerIndex] ||
          // If the settings for the current stain type and viewer index are not found, use any viewer's settings for the stain type, if available
          find(values(urlStainToBaseSlideSettings?.[props.stainTypeId])) ||
          defaultBaseSlideVisualSettings
        : defaultBaseSlideVisualSettings,
    [viewerIndex, urlStainToBaseSlideSettings, props.stainTypeId]
  );

  const baseSlideVisualSettings = {
    ...settingsFromUrl,
    ...(viewerBaseSlideSettings.value?.[slideId] || {}),
  };

  // Reset the base slide settings when the slide in the viewer changes
  useEffect(() => {
    if (viewerBaseSlideSettings) {
      viewerBaseSlideSettings.value = { [slideId]: settingsFromUrl };
    }
  }, [slideId, settingsFromUrl, viewerBaseSlideSettings]);

  const [, startTransition] = useTransition();

  const applyNewViewSettings = useCallback(
    (newViewSettings: Partial<BaseSlideVisualSettings>) => {
      const newViewSettingsWithPrevious = applyChangesToBaseSlideViewSettings({
        changes: newViewSettings,
        slideId,
        viewerIndex,
      });

      if (props.stainTypeId) {
        startTransition(() => {
          setUrlStainToBaseSlideSettings((currentUrlStainToBaseSlideSettings) => ({
            ...currentUrlStainToBaseSlideSettings,
            [props.stainTypeId]: {
              ...currentUrlStainToBaseSlideSettings?.[props.stainTypeId],
              [viewerIndex]: newViewSettingsWithPrevious,
            },
          }));
        });
      }
    },
    [slideId, viewerIndex, props.stainTypeId, setUrlStainToBaseSlideSettings]
  );

  const handleToggleShowSlide = useCallback(() => {
    const previousShowValue = baseSlideVisualSettings?.show;
    applyNewViewSettings({ show: !previousShowValue });
  }, [applyNewViewSettings, baseSlideVisualSettings?.show]);

  const handleChangeSlideOpacity = useCallback(
    (newOpacity: number) => applyNewViewSettings({ opacity: newOpacity }),
    [applyNewViewSettings]
  );

  const currentOpacity = baseSlideVisualSettings?.opacity ?? 0;

  return (
    <ToggleableSlider
      value={currentOpacity}
      disabled={false}
      checked={Boolean(baseSlideVisualSettings?.show)}
      onToggleChange={handleToggleShowSlide}
      onValueChange={handleChangeSlideOpacity}
    />
  );
};
