import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/lab';
import { useSignals } from '@preact/signals-react/runtime';
import { filter, find, includes, isEmpty, lowerCase, map } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import { FeatureMetadata } from 'components/Procedure/useSlideChannelsAndResults/featureMetadata';
import RasterHeatmapItem from './RasterHeatmapItem';
import { useSelectHeatmap } from './selectHeatmap';
import { useUpdateRasterHeatmapsSettingsOnChange } from './useUpdateRasterHeatmapsSettingsOnChange';

interface RasterHeatmapTreeProps {
  viewerIndex: number;
  slideId: string;
  stainTypeId: string;
  heatmaps: FeatureMetadata[];
  filterText: string;
  hideOrchestrationId?: boolean;
  hideOpacityWhenNotSelected?: boolean;
  onEmptyFilter?: () => void;
  addOrchestrationIdToUrl?: boolean;
}

export const RasterHeatmapTree: React.FC<RasterHeatmapTreeProps> = ({
  viewerIndex,
  slideId,
  stainTypeId,
  heatmaps,
  filterText,
  hideOrchestrationId,
  hideOpacityWhenNotSelected,
  onEmptyFilter,
  addOrchestrationIdToUrl,
}) => {
  useSignals();
  const didApplyInitialSettings = useUpdateRasterHeatmapsSettingsOnChange({
    viewerIndex,
    slideId,
    heatmaps,
    stainTypeId,
    addOrchestrationIdToUrl,
  });

  const [filterHeatmapsExpanded, setFilterHeatmapsExpanded] = useState([]);
  const [heatmapsExpanded, setHeatmapsExpanded] = useState<string[]>([]);

  // On first render, set the default expanded state based on selected heatmaps
  useEffect(() => {
    if (!isEmpty(heatmapsExpanded)) {
      return;
    }
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    const slideLayerVisualizationSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];

    const currentlySelectedHeatmaps = filter(
      heatmaps,
      ({ id }) => slideLayerVisualizationSettings?.[id]?.value?.selected
    );
    setHeatmapsExpanded(map(currentlySelectedHeatmaps, 'id'));
  }, [heatmaps, viewerIndex, slideId]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setHeatmapsExpanded(nodeIds);
  };

  const heatmapIncludesFilterText = (heatmap: FeatureMetadata) => {
    if (filterText === '') return true;

    const lowerCaseFilter = lowerCase(filterText);

    if (find(heatmap.nestedItems, (nestedItems) => includes(lowerCase(nestedItems.displayName), lowerCaseFilter))) {
      setFilterHeatmapsExpanded((prevDefaultExpanded) => [...prevDefaultExpanded, heatmap.id]);
      return true;
    }

    if (includes(lowerCase(heatmap.displayName), lowerCaseFilter)) {
      return true;
    }

    return false;
  };

  const filterHeatmaps = useMemo(() => {
    setFilterHeatmapsExpanded([]);

    if (filterText === '') {
      return heatmaps;
    } else {
      const filteredHeatmaps = filter(heatmaps, heatmapIncludesFilterText);

      if (isEmpty(filteredHeatmaps)) {
        onEmptyFilter?.();
      }
      return filteredHeatmaps;
    }
  }, [filterText, heatmaps]); // Could be buggy

  const { changeHeatmapSelectionState } = useSelectHeatmap({
    viewerIndex,
    slideId,
    heatmaps,
    stainTypeId,
    setHeatmapsExpanded,
    addOrchestrationIdToUrl,
  });

  const allExpanded = useMemo(
    () => [...filterHeatmapsExpanded, ...heatmapsExpanded],
    [filterHeatmapsExpanded, heatmapsExpanded]
  );

  return didApplyInitialSettings ? (
    <TreeView
      expanded={allExpanded}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
    >
      {map(filterHeatmaps, (heatmap) => {
        return (
          <RasterHeatmapItem
            hideOpacityWhenNotSelected={hideOpacityWhenNotSelected}
            viewerIndex={viewerIndex}
            slideId={slideId}
            stainTypeId={stainTypeId}
            key={heatmap.id}
            heatmap={heatmap}
            addOrchestrationIdToUrl={addOrchestrationIdToUrl}
            filterText={filterText}
            isExpanded={includes(allExpanded, heatmap.id)}
            handleChangeSelectHeatmap={changeHeatmapSelectionState}
            hideOrchestrationId={hideOrchestrationId}
          />
        );
      })}
    </TreeView>
  ) : (
    <CircularProgress />
  );
};
