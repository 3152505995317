import { Feature, TodoDatum } from 'interfaces/annotation';
import markerAnnotationService, { InnerTodo } from './markerAnnotationService';

function isValidAnnotation(todo: InnerTodo): boolean {
  return markerAnnotationService.isValid(todo);
}

function serializeAnnotationObject(todo: InnerTodo): { markers: Feature[] } {
  // serializes from proper GeoJSON format to Semi-GeoJSON used in the db
  return markerAnnotationService.serialize(todo);
}

function deserializeAnnotationObject(todo: TodoDatum, sortMarkers: boolean): InnerTodo {
  // deserializes from server response (Semi-GeoJSON) to proper GeoJSON format
  // todo: remove todos!!! it should not be part of the api
  return markerAnnotationService.deserialize(todo, sortMarkers);
}

function getNewObject(todoText: string): InnerTodo {
  const dummyTodo: TodoDatum = { todo: todoText, markers: [] };
  return markerAnnotationService.deserialize(dummyTodo);
}

const annotationsService = {
  serializeAnnotationObject,
  deserializeAnnotationObject,
  getNewObject,
  isValidAnnotation,
};

export default annotationsService;
