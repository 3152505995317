import katex from 'katex';

import {
  every,
  filter,
  find,
  includes,
  isEmpty,
  isNumber,
  isString,
  lowerCase,
  memoize,
  slice,
  split,
  startCase,
  startsWith,
  upperCase,
} from 'lodash';

import { FeatureFormatter } from 'interfaces/featureFormatter';
import { FormattedFeature } from 'interfaces/features';
import { Slide } from 'interfaces/slide';
import { isBrackets } from 'utils/formatBrackets';
import { formatBracketsFeature, getFeatureNameBrackets } from 'utils/formatBrackets/formatBracketsFeature';
import { FormatBracketsOptions } from 'utils/formatBrackets/formatBracketsOptions';
import { formatNumber, humanize } from 'utils/helpers';

const abbreviations = ['pfsDays'];

const checkForCountInOldKeyFormat = (inputString: string) => {
  const words = split(inputString, '_');
  return includes(words, 'count');
};

const featuresAliases: { [key: string]: string } = {
  'tumor cell (pdl1+) to tumor cell (pdl1+) and tumor cell (pdl1-) ratio in tumor': 'TPS',
  'tumor cell (pdl1+) and immune cell (pdl1+) to tumor cell (pdl1+) and tumor cell (pdl1-) ratio in tumor': 'CPS',
};

export const filterSlidesWithResults = (slides: Slide[]) => {
  return filter(slides, (slide) => !isEmpty(slide.experimentResults));
};

export const getFeatureDisplayName = (key: string, context: FormatBracketsOptions) => {
  if (key === '') {
    return key;
  }

  const featureConfig = context.uiSettings.webappFeaturesConfig && context.uiSettings.webappFeaturesConfig[key];
  if (!featureConfig) {
    if (key === 'biopsySiteId') {
      return 'Biopsy Site';
    }
    if (key === 'cancerTypeId') {
      return 'Cancer Type';
    }
    if (includes(abbreviations, lowerCase(key))) {
      return upperCase(key);
    } else if (isBrackets(key)) {
      const formattedFeature: string = formatBracketsFeature(key, context);
      if (formattedFeature in featuresAliases) {
        return featuresAliases[formattedFeature];
      } else {
        return formattedFeature;
      }
    } else {
      return startCase(humanize(key));
    }
  }

  return featureConfig.displayName;
};

export type DistanceBasedOptions = {
  bucketSize: number;
  lowerBound: number;
  upperBound: number;
  yScaleTitle: string;
};

export type OptionFeatures = DistanceBasedOptions | undefined;

export const getOptionsFromFeatureName = (featureName: string): OptionFeatures => {
  const featureBrackets = getFeatureNameBrackets(featureName);

  if (!featureBrackets || isEmpty(featureBrackets)) {
    return undefined;
  } else if (featureBrackets['inner_dist'] && featureBrackets['outer_dist'] && featureBrackets['interval_size']) {
    return {
      bucketSize: parseInt(featureBrackets['interval_size']),
      lowerBound: parseInt(featureBrackets['inner_dist']) * -1,
      upperBound: parseInt(featureBrackets['outer_dist']),
      // TODO: replace this with correct logic
      yScaleTitle:
        featureBrackets.f === '34'
          ? 'Density (Cells / µm²)'
          : featureBrackets.f === '37'
          ? 'Density (Cells / mm²)'
          : 'Value',
    };
  }

  return undefined;
};

const featureIdDisplayNameEqualsToFeatureDisplayName = memoize(
  (featureId: string, featureDisplayName: string, context: FormatBracketsOptions) => {
    const formatterNameOverrideDisplayName = getFeatureDisplayName(featureId, context);

    return formatterNameOverrideDisplayName === featureDisplayName;
  },
  (featureId: string, featureDisplayName: string) => {
    return `${featureId}-${featureDisplayName}`;
  }
);

const isFeatureKeyContainsFeatureFormatterId = (featureKey: string, featureId: string) => {
  const featureIdParts = slice(split(featureId, '<'), 1);

  return every(featureIdParts, (part) => includes(featureKey, `<${part}`));
};

export const findFormatterByKey = (
  featureKey: string,
  nameOverrideOptions: {
    featureDisplayName: string;
    context: FormatBracketsOptions;
  }
): FeatureFormatter & { nonFormattedUnits?: string } => {
  const featureFormatters = nameOverrideOptions?.context?.featureFormatters || [];
  const formatter = find(featureFormatters, (item) => {
    if (!item) {
      return false;
    }
    if (isBrackets(featureKey)) {
      if (item.nameOverride) {
        // need to be check if the display name is the same as the featureId display name
        return featureIdDisplayNameEqualsToFeatureDisplayName(
          item.featureId,
          nameOverrideOptions.featureDisplayName,
          nameOverrideOptions.context
        );
      } else {
        // check if the featureKey contain all the brackets parts in item.featureId
        return isFeatureKeyContainsFeatureFormatterId(featureKey, item.featureId);
      }
      // TODO: remove this when studies with the old feature key format are deprecated
    } else if (checkForCountInOldKeyFormat(featureKey)) {
      return startsWith('<f:3>', item.featureId);
    }
    return false;
  });

  if (formatter) {
    const formattedUnits = formatter.units ? katex.renderToString(formatter.units) : '';

    return {
      id: formatter.id,
      featureId: formatter.featureId,
      units: formattedUnits,
      valueFormat: formatter.valueFormat || null,
      smallerThan: formatter.smallerThan || null,
      exportValueFormat: formatter.exportValueFormat || null,
      nonFormattedUnits: formatter.units,
      nameOverride: formatter.nameOverride || null,
    };
  }

  return {};
};

export const getNameOverrideOrDisplayName = (featureKey: string, context: FormatBracketsOptions) => {
  const featureDisplayName = getFeatureDisplayName(featureKey, context);
  const formatter = findFormatterByKey(featureKey, {
    featureDisplayName,
    context,
  });

  return formatter?.nameOverride || featureDisplayName;
};

export const getFeatureFormattedValue = (
  featureValue: string | number,
  format?: string,
  smallerThan?: number,
  forExport?: boolean
) => {
  if (isNumber(featureValue)) {
    const featureValueNumber = Number(featureValue);
    if (featureValueNumber < smallerThan && featureValueNumber !== 0) {
      return `<${smallerThan}`;
    }
    return formatNumber(featureValueNumber, format, forExport);
  }
  if (isString(featureValue)) {
    return featureValue;
  }
  return 'None';
};

export const isDistanceBasedFeatureKey = (feature: string): boolean =>
  includes(feature, '<f:34') || includes(feature, '<f:37');
export const isDistanceBasedFormattedFeature = (feature: FormattedFeature): boolean =>
  isDistanceBasedFeatureKey(feature.key);
