import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton,
  Link as MuiLink,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getModel } from 'api/platform';
import { fetchStudies } from 'api/study';
import { getInternalUsers } from 'api/userCredentials';
import { FlowClassName } from 'interfaces/experimentResults';
import { CalculateFeaturesManifest, InferenceManifest, InferenceParams, Job, JobType } from 'interfaces/job';
import {
  filter,
  find,
  first,
  flatMap,
  fromPairs,
  groupBy,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  join,
  keyBy,
  map,
  startCase,
  toPairs,
  toString,
  values,
} from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useUiSettings } from 'utils/queryHooks/uiConstantsHooks';
import { useCurrentLabId } from 'utils/useCurrentLab';
import { useNavigationToViewerPage } from 'utils/useNavigationToViewerPage';
import { getModelId } from '../CalculateFeatures/utils';
import { classNamesOptions, modelTypeCell, modelTypeOptions } from './inferenceFieldsOptions';

const SKIP = 'skip';

interface JobDataProps {
  job: Job;
  isLoading?: boolean;
  onClosed?: () => void;
}

export const JobDetails: React.FC<JobDataProps> = ({ job, isLoading = false, onClosed }) => {
  const { labId } = useCurrentLabId();

  const { data: studies } = useQuery(['studies', labId], fetchStudies(labId));
  const { data: internalUsers } = useQuery(['internalUsers'], getInternalUsers);
  const modelId = getModelId(first((job?.params as InferenceParams)?.modelPaths));
  const { data: modelData } = useQuery({
    queryKey: ['model', modelId],
    queryFn: ({ signal }) => getModel(modelId, signal),
    enabled: job?.type === JobType.Inference && !isEmpty(modelId),
  });

  const internalUsersById = useMemo(() => keyBy(internalUsers, 'id'), [internalUsers]);

  const { uiSettings } = useUiSettings();
  const jobManifest = job?.manifest;
  const slideIds = job?.type === JobType.CalculateFeatures ? flatMap(jobManifest) : jobManifest;

  const currStudy = job?.studyId ? find(studies, (study) => job.studyId === study.id)?.name : undefined;
  const subTasks = filter(
    job?.children,
    (childrenJob) =>
      childrenJob?.flowClassName &&
      includes([FlowClassName.ConcurrentInferenceFlow, FlowClassName.CalculateFeatures], childrenJob.flowClassName)
  );

  const subTasksStatusBySlide = keyBy(
    flatMap(subTasks, (inferenceFlow: Job) => {
      if (inferenceFlow?.flowClassName === FlowClassName.ConcurrentInferenceFlow) {
        return map(inferenceFlow?.manifest as InferenceManifest, (slide: { slide_id: string }) => {
          return {
            slideId: slide.slide_id,
            status: inferenceFlow?.status,
          };
        });
      } else if (inferenceFlow?.flowClassName === FlowClassName.CalculateFeatures) {
        const manifest = inferenceFlow?.manifest as CalculateFeaturesManifest;
        const manifestSlides = flatMap(manifest, (entry) => values(entry.slides));
        return map(manifestSlides, (slideId: string) => ({
          slideId,
          status: inferenceFlow?.status,
        }));
      }
    }),
    'slideId'
  );

  const lengthSubTasksByStatus = fromPairs(
    map(toPairs(groupBy(subTasks, 'status')), ([key, value]) => [key, value.length])
  );

  // No row will be created in the database if inference was previously run on one of the slides with the same parameters.
  // In this case, the number of inference sub tasks will be smaller than the number of slides in the manifest
  // When the job is completed, we know this is the case, otherwise, we sum the number of sub tasks by the job status.
  const defaultSubTaskStatusByJobStatus =
    job?.status === 'completed' ? SKIP : job?.status === 'running' ? 'pending' : job?.status;

  if (subTasks.length < jobManifest?.length) {
    const difference = jobManifest.length - subTasks.length;

    lengthSubTasksByStatus[defaultSubTaskStatusByJobStatus] =
      (lengthSubTasksByStatus[defaultSubTaskStatusByJobStatus] ?? 0) + difference;
  }

  const requestSenderToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['requestSender'], ({ value, label }) => [value, label]) || []
  );
  const jobStatusToDisplayText = fromPairs(
    map(uiSettings?.enumDisplayNames?.['jobStatus'], ({ value, label }) => [value, label]) || []
  );
  jobStatusToDisplayText[SKIP] = 'Skipped';

  const modelTypeOptionsByValue = keyBy(modelTypeOptions, 'value');
  const classNamesOptionsByValue = keyBy(classNamesOptions, 'value');

  const stringToDisplay = (variable: any) => (isArray(variable) ? join(variable, ', ') : variable);
  const assignmentIds =
    job?.type === JobType.Inference ? stringToDisplay((job?.params as InferenceParams)?.assignmentIds) : '';
  const classNames =
    job?.type === JobType.Inference
      ? stringToDisplay(
          map(
            (job?.params as InferenceParams)?.classNames,
            (className) => classNamesOptionsByValue[className]?.text || className
          )
        )
      : '';
  // heatmapNames and modelPaths  saved as array but currently there is only one heatmap and model per job
  const modelPaths =
    job?.type === JobType.Inference ? stringToDisplay((job?.params as InferenceParams)?.modelPaths) : '';
  const heatmapNames =
    job?.type === JobType.Inference ? stringToDisplay((job?.params as InferenceParams)?.heatmapNames) : '';

  const runType = job?.type === JobType.Inference ? (job?.params as InferenceParams)?.runType : undefined;

  const tissueMaskFromAnnotations =
    job?.type === JobType.Inference ? (job?.params as InferenceParams)?.tissueMaskFromAnnotations : undefined;

  const tissueSegmentationModelOverride =
    job?.type === JobType.Inference ? (job?.params as InferenceParams)?.tissueSegmentationModelOverride : undefined;

  const { getUrlToSlidePage } = useNavigationToViewerPage();

  return (
    <Grid container p={2} direction="column" spacing={2} flexWrap="nowrap">
      <Grid container item alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h3">{job?.name ?? job?.orchestrationId}</Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <IconButton onClick={onClosed}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="body1">{job?.description}</Typography>
      </Grid>

      <Grid item container direction="column">
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="body1">Started at: </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"> {job?.startedAt && moment(job?.startedAt).format('lll')}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="body1">Source: </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1"> {requestSenderToDisplayText[job?.requestSender]}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="body1">Run by: </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              {internalUsersById[job?.userId]?.name ?? internalUsersById[job?.userId]?.primaryEmail ?? job?.userId}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={1}>
        <Grid item>
          <Typography variant="body1">Status Message: </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{job?.statusMessage ?? ''}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="rectangular" height={150} />
        ) : (
          <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h4">External Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="body1">Task Id:</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body1">{job?.externalTaskId}</Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="body1">Link:</Typography>
                  </Grid>
                  <Grid xs={10} item sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip placement="top-start" title={job?.externalTaskLink}>
                      <MuiLink color="inherit" underline="hover" href={job?.externalTaskLink} target="_blank">
                        {job?.externalTaskLink}
                      </MuiLink>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="rectangular" height={150} />
        ) : (
          <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h4">Parameters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                {heatmapNames && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Heatmap name: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1"> {heatmapNames}</Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid item container spacing={1}>
                  <Grid item>
                    <Typography variant="body1">Study: </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1"> {currStudy}</Typography>
                  </Grid>
                </Grid>
                {runType && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Model type: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">{modelTypeOptionsByValue[runType]?.text || runType}</Typography>
                    </Grid>
                  </Grid>
                )}
                {job?.type === JobType.Inference && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">Model: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Tooltip placement="top-start" title={modelPaths}>
                        <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {modelPaths}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {modelData?.meta?.modelType === modelTypeCell.apiModelValue && (
                  <>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">dedup Value: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{(job?.params as InferenceParams)?.dedupValue}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Use Dynamic Cell Detection: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {isBoolean((job?.params as InferenceParams)?.useDynamicCellDetection) &&
                            startCase(toString((job?.params as InferenceParams)?.useDynamicCellDetection))}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Dynamic Cell Detection Config: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {(job?.params as InferenceParams)?.dynamicCellDetectionConfig}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {job?.type === JobType.Inference && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">ROI mask: </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {isBoolean(tissueMaskFromAnnotations) && startCase(toString(tissueMaskFromAnnotations))}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {!isEmpty(tissueSegmentationModelOverride) && (
                  <Grid item container spacing={1}>
                    <Grid item>
                      <Typography variant="body1">TSM Model: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Tooltip placement="top-start" title={tissueSegmentationModelOverride}>
                        <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {tissueSegmentationModelOverride}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {tissueMaskFromAnnotations && (
                  <Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Assignments Ids: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{assignmentIds}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item>
                        <Typography variant="body1">Class names: </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">{classNames}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid item>
        {isLoading ? (
          <Skeleton variant="rectangular" height={150} />
        ) : (
          !isEmpty(job?.manifest) && (
            <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">Sub Tasks</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item container direction="column" spacing={1}>
                  <Grid item container direction="column">
                    {map(toPairs(lengthSubTasksByStatus), ([key, value]) => {
                      return (
                        <Grid item container justifyContent="space-between">
                          <Grid item>
                            <Typography variant="h5">{value}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h5">{jobStatusToDisplayText[key]}</Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item container justifyContent="space-between">
                    <Grid item>
                      <Typography variant="h4">Slide Id</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h4">Status</Typography>
                    </Grid>
                  </Grid>

                  {map(slideIds, (slideId: string) => {
                    return (
                      <Grid item container alignItems="center" justifyContent="space-between">
                        <Grid item xs={9}>
                          <Link
                            to={getUrlToSlidePage({
                              slideId,
                              labId,
                              caseStudyId: job?.studyId,
                            })}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Typography variant="h5">{slideId}</Typography>
                          </Link>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">
                            {
                              jobStatusToDisplayText[
                                subTasksStatusBySlide[slideId]?.status || defaultSubTaskStatusByJobStatus
                              ]
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        )}
      </Grid>
    </Grid>
  );
};
