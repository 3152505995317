import { List, ListItemButton, ListItemIcon, Tooltip, Typography } from '@mui/material';
import { cloneDeep, compact, includes, map } from 'lodash';
import React from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { TodoOption } from 'interfaces/annotation';
import { viewerClickData } from '../../../SlideDeckGLViewer';
import { FeatureCollection } from '../../NebulaGLExtensions/geojson-types';
import { viewerAnnotationData } from '../useActiveAnnotationDraft';
import ContextMenuIcon from './ContextMenuIcon';

export interface AnnotationItem {
  text: string;
  optionName: string;
  color: string;
  positive: boolean;
  selected: boolean;
  icon?: React.ReactNode;
}

export const AnnotationOptionsMenu: React.FC<{
  viewerIndex: number;
  featureIndex: number;
  todoOptions: TodoOption[];
  annotationId: number;
  annotationAssignmentId: number;
  todoName: string;
  annotationData: FeatureCollection;
}> = ({ featureIndex, todoOptions, viewerIndex, annotationId, annotationAssignmentId, todoName, annotationData }) => {
  useSignals();
  const annotationFeatures = annotationData?.features;
  const feature = annotationFeatures?.[featureIndex];
  const isPoint = feature?.geometry.type === 'Point';
  const isRoi = feature?.properties?.shapeSubType === 'rect';

  const annotationOptions: AnnotationItem[] =
    feature?.properties?.markerType === 'tagger_annotation'
      ? compact(
          map(todoOptions, (option) =>
            !option.className ||
            (isPoint && option.className.toLocaleLowerCase() == 'cell') ||
            (isRoi && includes(option.displayName.toLocaleLowerCase(), 'roi')) ||
            option.className.toLocaleLowerCase() === feature.properties?.shapeSubType.toLocaleLowerCase()
              ? {
                  text: option.displayName || option.name,
                  optionName: option.name,
                  positive: option.positive,
                  color: option.color,
                  selected: option.name === feature.properties.optionName,
                }
              : null
          )
        )
      : [];

  return (
    <List>
      {map(annotationOptions, (item) => (
        <Tooltip title={item.optionName} key={`${item.optionName}-${item.color}`}>
          <ListItemButton
            selected={item.selected}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              if (viewerClickData[viewerIndex]) {
                viewerClickData[viewerIndex].value = null;
              }
              if (!annotationFeatures?.[featureIndex]) {
                console.warn("Couldn't find feature in draft", {
                  editedAnnotationData: annotationData,
                  featureIndex,
                });
                return;
              }
              const editedAnnotationData: FeatureCollection = cloneDeep(annotationData);
              editedAnnotationData.features = cloneDeep(annotationFeatures) as typeof editedAnnotationData.features;
              const editedFeature = editedAnnotationData.features[featureIndex];
              editedFeature.properties = {
                ...(editedFeature?.properties || {}),
                diagnosis: item.optionName,
                annotationId,
                annotationAssignmentId,
                todo: todoName,
                markerType: editedFeature?.properties?.markerType || 'tagger_annotation',
              };
              if (viewerAnnotationData[viewerIndex]) {
                viewerAnnotationData[viewerIndex].value = editedAnnotationData;
              }
              return true;
            }}
            tabIndex={0}
          >
            {item.color && (
              <ListItemIcon>
                <ContextMenuIcon
                  positive={item.positive}
                  displayPosNeg={isPoint}
                  // TODO: implement with brushtool
                  // hole={isHole(item.optionName)}
                  hole={false}
                  color={item.color}
                />
              </ListItemIcon>
            )}
            <Typography title={item.text}>
              {item.icon} {item.text}
            </Typography>
          </ListItemButton>
        </Tooltip>
      ))}
    </List>
  );
};
