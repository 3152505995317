import { Button, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { useMutation } from '@tanstack/react-query';
import { castArray, filter, find, flatMap, includes, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { Feature, Geometry } from '@turf/helpers';
import { runCalculateFeatures } from 'api/platform';
import { useJobs } from 'components/Pages/Jobs/useJobs';
import { secondaryAnalysisOptions } from 'components/Procedure/Header/SlideInteractionMenu/secondaryAnalysis';
import {
  secondaryAnalysisExclusionModes,
  secondaryAnalysisInclusionModes,
  secondaryAnalysisPolygons,
} from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisLayer';
import { useSecondaryAnalysisOrchestrationIdForViewer } from 'components/Procedure/SlidesViewer/DeckGLViewer/layers/SecondaryAnalysisLayer/useSecondaryAnalysisQueryParams';
import { Inputs } from 'interfaces/calculateFeatures';
import { CalculateFeaturesJob, JobType, SecondaryAnalysisInput } from 'interfaces/job';
import { Permission } from 'interfaces/permissionOption';
import moment from 'moment';
import { getActiveAnnotationAssignmentIdViewerKey } from 'services/annotations/useAnnotationQueryParams';
import queryClient from 'utils/queryClient';
import { useEncodedFilters } from 'utils/useEncodedFilters';
import { usePermissions } from 'utils/usePermissions';

export const geojsonFeaturesToMultiPolygon = (features: Feature[]) => {
  const coordinates: any[] = flatMap(features, (feature: Feature) => (feature.geometry as Geometry).coordinates);
  return {
    type: 'MultiPolygon',
    coordinates: coordinates,
  };
};
interface SecondaryAnalysisTriggerProps {
  slideId: string;
  viewerIndex: number;
  autoInternallyApprove: boolean;
  orchestrationId: string;
  studyId: string;
  hasMultiplePublishedOrchestrationIds?: boolean;
}

const SNACK_BAR_KEY_SECONDARY_ANALYSIS = 'secondary-analysis';

export const SecondaryAnalysisTrigger: React.FunctionComponent<
  React.PropsWithChildren<SecondaryAnalysisTriggerProps>
> = ({
  slideId,
  viewerIndex,
  autoInternallyApprove,
  orchestrationId,
  hasMultiplePublishedOrchestrationIds,
  studyId,
}) => {
  useSignals();
  const { queryParams } = useEncodedFilters();
  const params = useParams();
  const [caseIdFromParam] = useQueryParam('caseId', NumberParam);
  const caseId = queryParams.slidesMode ? Number(caseIdFromParam) : Number(params.id);
  const { hasPermission } = usePermissions();
  const canRunSecondaryAnalysis = hasPermission(Permission.RunSecondaryAnalysis);
  const [useDeckGL] = useQueryParam('useDeckGL', BooleanParam);
  const [activeAnnotationAssignmentId] = useQueryParam(
    getActiveAnnotationAssignmentIdViewerKey(viewerIndex),
    NumberParam
  );
  const [secondaryAnalysisOrchestrationId, setSecondaryAnalysisOrchestrationId] =
    useSecondaryAnalysisOrchestrationIdForViewer(viewerIndex);
  const [, setSecondaryAnalysisAreaSelectionMode] = useQueryParam('secondaryAnalysisAreaSelectionMode', StringParam);

  const secondaryAnalysisFeature = secondaryAnalysisPolygons[viewerIndex].value;

  const secondaryAnalysisFeatures = filter(
    secondaryAnalysisFeature?.features,
    (feature) => feature.properties.orchestrationId === orchestrationId
  );
  const isSecondaryAnalysisActive = secondaryAnalysisOrchestrationId === orchestrationId;
  const isOtherSecondaryAnalysisActive =
    secondaryAnalysisOrchestrationId && secondaryAnalysisOrchestrationId !== orchestrationId;

  const { data: jobsQueryResponse, isLoading: isLoadingOriginalJob } = useJobs({
    enabled: Boolean(orchestrationId) && canRunSecondaryAnalysis,
    additionalFilters: { orchestrationIds: castArray(orchestrationId), studyId },
    fullData: true,
  });

  const orchestrationIdJobs = jobsQueryResponse?.jobs;

  if (!isEmpty(orchestrationIdJobs) && orchestrationIdJobs.length > jobsQueryResponse?.totalJobs) {
    console.error('Got more jobs for orchestration than a single page size, need to implement paginated query', {
      orchestrationIdJobs,
      totalJobs: jobsQueryResponse?.totalJobs,
    });
  }

  const parentJob = find(orchestrationIdJobs, (job) => !job?.parentId);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const runCalculateFeaturesMutation = useMutation(runCalculateFeatures, {
    onError: () => {
      enqueueSnackbar('Error occurred, Calculate Features failed', { variant: 'error' });
    },
    onSuccess: () => {
      enqueueSnackbar('Calculate Features Start', { variant: 'success' });
      // Invalidate jobs query to get the new job
      queryClient.invalidateQueries(['jobs']);
      setSecondaryAnalysisOrchestrationId(null);
      setSecondaryAnalysisAreaSelectionMode(null);
      secondaryAnalysisPolygons[viewerIndex].value = null;
    },
    onSettled() {
      // Invalidate jobs query to get the new job
      queryClient.invalidateQueries(['jobs']);
      closeSnackbar(SNACK_BAR_KEY_SECONDARY_ANALYSIS);
    },
  });

  const runSecondaryAnalysis = () => {
    if (!parentJob) {
      console.error('Parent job not found');
      return;
    }
    if (parentJob?.type !== JobType.CalculateFeatures) {
      console.error('Parent job not CalculateFeatures', { parentJob });
      return;
    }
    if (!parentJob.params) {
      console.error('Parent job params not found', { parentJob });
      return;
    }

    const inclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
      includes(secondaryAnalysisInclusionModes, feature.properties.featureType)
    );

    const exclusionFeatures = filter(secondaryAnalysisFeatures, (feature) =>
      includes(secondaryAnalysisExclusionModes, feature.properties.featureType)
    );

    const secondaryAnalysisPolygonsToSend: SecondaryAnalysisInput = {
      [caseId]: {
        [slideId]: {
          ...(!isEmpty(inclusionFeatures)
            ? { inclusionMultipolygon: geojsonFeaturesToMultiPolygon(inclusionFeatures) }
            : {}),
          ...(!isEmpty(exclusionFeatures)
            ? { exclusionMultipolygon: geojsonFeaturesToMultiPolygon(exclusionFeatures) }
            : {}),
        },
      },
    };

    const newManifest = { [caseId]: [slideId] };

    const previousCaseInputs = (parentJob as CalculateFeaturesJob).params?.inputs?.[caseId];
    const newInputs: Inputs = { [caseId]: { [slideId]: previousCaseInputs?.[slideId] || [] } };

    const runTime = moment().format('YYYY-MM-DD HH:mm:ss');
    runCalculateFeaturesMutation.mutate({
      jobName: `${parentJob.name} - Secondary Analysis - ${runTime}`,
      jobDescription: `${parentJob.description} - Secondary Analysis - ${runTime}`,
      configParams: {
        secondaryAnalysisPolygons: secondaryAnalysisPolygonsToSend,
        primaryRunOrchestrationId: orchestrationId,
        autoInternallyApprove,
        manifest: newManifest,
        inputs: newInputs,
        postprocessing: (parentJob as CalculateFeaturesJob).params.postprocessing,
        features: (parentJob as CalculateFeaturesJob).params.features,
        gridBasedCalculationParams: (parentJob as CalculateFeaturesJob).params.gridBasedCalculationParams,
        visualizations: (parentJob as CalculateFeaturesJob).params.visualizations,
        studyId,
      },
    });

    enqueueSnackbar({
      variant: 'success',
      message: (
        <Grid container>
          <Grid item>
            <Typography>Waiting for Calculate Features to start</Typography>
          </Grid>
          <Grid item>
            <CircularProgress sx={{ marginLeft: 10 }} color="inherit" size={20} />
          </Grid>
        </Grid>
      ),
      key: SNACK_BAR_KEY_SECONDARY_ANALYSIS,
      autoHideDuration: null,
    });
  };

  return (
    useDeckGL &&
    canRunSecondaryAnalysis && (
      <Tooltip
        title={
          hasMultiplePublishedOrchestrationIds
            ? `Multiple published orchestration ids - will run on ${orchestrationId}`
            : ''
        }
      >
        <Grid item xs={12} container direction="row">
          <Grid item>
            <Tooltip
              title={
                isLoadingOriginalJob
                  ? 'Loading original job'
                  : activeAnnotationAssignmentId
                  ? 'Cannot run secondary analysis while annotation is active'
                  : isOtherSecondaryAnalysisActive
                  ? 'Cannot run secondary analysis while other secondary analysis is active'
                  : !parentJob
                  ? 'No job found'
                  : ''
              }
            >
              <span>
                <Button
                  disabled={
                    isLoadingOriginalJob ||
                    !parentJob ||
                    Boolean(activeAnnotationAssignmentId) ||
                    isOtherSecondaryAnalysisActive
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setSecondaryAnalysisOrchestrationId((currentId) => (currentId ? null : orchestrationId));
                    setSecondaryAnalysisAreaSelectionMode(
                      (previousMode) =>
                        // Don't change the previous mode, if set
                        previousMode || find(secondaryAnalysisOptions, { isDefault: true })?.editType
                    );
                  }}
                >
                  {isSecondaryAnalysisActive
                    ? isLoadingOriginalJob
                      ? 'Loading...'
                      : 'Cancel Recalculation'
                    : 'Select Areas for Recalculation'}
                </Button>
              </span>
            </Tooltip>
          </Grid>
          {!isLoadingOriginalJob && isSecondaryAnalysisActive && (
            <Grid item>
              <Tooltip
                title={!parentJob ? 'No job found' : isEmpty(secondaryAnalysisFeatures) ? 'No areas selected' : ''}
              >
                <span>
                  <Button
                    disabled={!isSecondaryAnalysisActive || isEmpty(secondaryAnalysisFeatures) || !parentJob}
                    onClick={(e) => {
                      e.stopPropagation();
                      runSecondaryAnalysis();
                    }}
                  >
                    Run Secondary Analysis
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Tooltip>
    )
  );
};

export default SecondaryAnalysisTrigger;
