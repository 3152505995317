import { filter, includes } from 'lodash';
import { useCallback } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';

export interface SlideResultsExpandedState {
  [viewerIndex: number]: {
    [slideStainTypeId: string]: string[];
  };
}

/**
 * Store the expanded state of the accordion in the URL to allow showing heatmaps when loading the page
 */
export const useSlideResultsExpandedState = ({
  title,
  viewerIndex,
  slideStainTypeId,
}: {
  title: string;
  viewerIndex: number;
  slideStainTypeId: string;
}) => {
  const [expandedSlideResults, setExpandedSlideResults] = useQueryParam<SlideResultsExpandedState>(
    'expandedSlideResults',
    JsonParam
  );

  const isExpanded = includes(expandedSlideResults?.[viewerIndex]?.[slideStainTypeId], title);

  const toggleExpanded = useCallback(() => {
    setExpandedSlideResults((prev) => {
      const prevExpandedResultsForSlide = prev?.[viewerIndex]?.[slideStainTypeId] || [];
      return {
        ...prev,
        [viewerIndex]: {
          ...prev?.[viewerIndex],
          [slideStainTypeId]: isExpanded
            ? filter(prevExpandedResultsForSlide, (expandedTitle) => expandedTitle !== title)
            : [...prevExpandedResultsForSlide, title],
        },
      };
    }, 'replaceIn');
  }, [setExpandedSlideResults, title]);
  return { isExpanded, toggleExpanded };
};
