import { useSignals } from '@preact/signals-react/runtime';
import {
  LayerVisualizationSettings,
  slidesLayerVisualizationSettings,
  useApplyChangesToSlideLayerVisualizationSettings,
} from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';

export const useGroupedLayerChanges = (
  viewerIndex: number,
  slideId: string,
  parentKey: string,
  layerName: string,
  layerUrlKey: string,
  stainTypeId: string
) => {
  useSignals();
  const applyChangesToSlideLayerVisualizationSettings = useApplyChangesToSlideLayerVisualizationSettings();

  const handleChange = (key: keyof LayerVisualizationSettings, value: any) => {
    return applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      stainTypeId,
      changes: [
        {
          layerId: `${parentKey}-${layerName}`,
          newSettings: { [key]: value },
          layerUrlKey: layerUrlKey,
        },
      ],
    });
  };

  const handleColorChange = (color: any) => {
    handleChange('color', color);
  };

  const handleOpacityChange = (opacity: number) => {
    handleChange('opacity', opacity);
  };

  const handleSelectChannel = (newValue: boolean, defaultOpacity: number = 100) => {
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }

    const previousSlideSettings = viewerSlideLayerVisualizationSettings.value?.[slideId];
    const newLayerSettings: LayerVisualizationSettings = {
      opacity: newValue ? defaultOpacity : 0,
      ...(previousSlideSettings?.[`${parentKey}-${layerName}`].value || {}),
      show: newValue,
      selected: newValue,
    };
    return applyChangesToSlideLayerVisualizationSettings({
      slideId,
      viewerIndex,
      stainTypeId,
      changes: [
        {
          layerId: `${parentKey}-${layerName}`,
          newSettings: newLayerSettings,
          layerUrlKey: layerUrlKey,
        },
      ],
    });
  };

  const handleShowChange = (defaultOpacity: number = 100) => {
    const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];
    if (!viewerSlideLayerVisualizationSettings) {
      console.warn(`Invalid viewerIndex: ${viewerIndex}`);
      return;
    }

    handleOpacityChange(
      viewerSlideLayerVisualizationSettings.value?.[slideId]?.[`${parentKey}-${layerName}`].value.opacity === 0
        ? defaultOpacity
        : 0
    );
  };

  return {
    handleColorChange,
    handleOpacityChange,
    handleSelectChannel,
    handleShowChange,
  };
};
