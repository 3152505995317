import { Box } from '@mui/system';
import {
  DataGrid,
  DataGridProps,
  GridEventListener,
  GridFilterModel,
  GridPaginationModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid';
import { useCommonDataGridStyles } from 'components/atoms/BaseDataGrid/commonStyles';
import React, { useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { DataGridPagination } from '../DataGridPagination';
import { GridActionToolbar } from './GridActionToolbar';

const pageSizeOptions = [10, 25, 50, 100];

const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
  event.defaultMuiPrevented = true;
};

const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
  event.defaultMuiPrevented = true;
};

interface SettingsDataGridProps extends DataGridProps {
  handleAdd: () => void;
  addText: string;
  height?: CSSStyleDeclaration['height'];
}

export const SettingsDataGrid: React.FC<React.PropsWithChildren<SettingsDataGridProps>> = ({
  handleAdd,
  addText,
  height = '80vh',
  ...props
}) => {
  const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const [localSearch, setLocalSearch] = React.useState<string>('');

  const commonDataGridStyles = useCommonDataGridStyles();

  const sx = React.useMemo(
    () => ({ ...commonDataGridStyles, ...((props.sx || {}) as any) }),
    [commonDataGridStyles, props.sx]
  );

  const slots = React.useMemo(
    () => ({
      pagination: DataGridPagination,
      toolbar: GridActionToolbar,
      ...props?.slots,
    }),
    [props?.slots]
  );

  const slotProps = useMemo(
    () => ({
      toolbar: {
        addText,
        handleAdd,
        localSearch,
        setLocalSearch,
      },
      pagination: {
        SelectProps: {
          sx: { width: 'auto' },
        },
      },
    }),
    [addText, handleAdd, localSearch, setLocalSearch]
  );

  const [debouncedLocalSearch, { isPending }] = useDebounce(localSearch, 100);

  const [columnFilter, setColumnFilters] = React.useState<GridFilterModel>({
    items: [],
  });
  const filterModel = React.useMemo(
    () => ({
      items: [],
      ...columnFilter,
      quickFilterValues: [debouncedLocalSearch],
    }),
    [debouncedLocalSearch, columnFilter]
  );

  return (
    <Box height={height}>
      <DataGrid
        pagination
        editMode="row"
        paginationMode="client"
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        filterModel={filterModel}
        onFilterModelChange={setColumnFilters}
        loading={isPending()}
        getRowClassName={({ row }) => (row?.deletedAt ? 'deleted-row' : '')}
        {...props}
        sx={sx}
        slots={slots}
        slotProps={slotProps}
      />
    </Box>
  );
};
