import { DeckGLProps } from '@deck.gl/react/typed';
import { useSignals } from '@preact/signals-react/runtime';
import { compact, concat, filter, flatMap, includes, isEmpty, join, map, reduce, sortBy, uniq, values } from 'lodash';
import { useMemo } from 'react';

import { COORDINATE_SYSTEM } from '@deck.gl/core/typed';
import { GeoJsonLayer } from '@deck.gl/layers/typed';
import { useJobs } from 'components/Pages/Jobs/useJobs';
import { slidesLayerVisualizationSettings } from 'components/Procedure/Infobar/slidesVisualizationAndConfiguration';
import {
  getAllFlatMapDeepHeatmapsFromSlide,
  SlideWithChannelAndResults,
} from 'components/Procedure/useSlideChannelsAndResults/utils';
import { CalculateFeaturesJob } from 'interfaces/job';
import { Geometry, MultiPolygon } from './NebulaGLExtensions/geojson-types';

/**
 * We need to fix the malformed MultiPolygon that comes from the API, which misses one dimension in the coordinates.
 * @param malformedMultiPolygon: The malformed MultiPolygon.
 * @returns The fixed MultiPolygon.
 */
const fixMultiPolygon = (malformedMultiPolygon: any): MultiPolygon => {
  if (!malformedMultiPolygon?.coordinates) {
    return malformedMultiPolygon;
  }
  return {
    type: 'MultiPolygon',
    coordinates: [malformedMultiPolygon.coordinates],
  };
};

export const useSecondaryAnalysisROILayers = ({
  slide,
  rescaleFactor,
}: {
  slide: SlideWithChannelAndResults;
  rescaleFactor?: number;
}): DeckGLProps['layers'] => {
  useSignals();
  const slideId = slide?.id;
  const viewerIndex = slide?.viewerIndex;
  const viewerSlideLayerVisualizationSettings = slidesLayerVisualizationSettings[viewerIndex];

  const heatmapsSettings = viewerSlideLayerVisualizationSettings?.value?.[slideId];
  const allSecondaryHeatmaps = useMemo(
    () =>
      filter(getAllFlatMapDeepHeatmapsFromSlide(slide), ({ primaryRunOrchestrationId }) =>
        Boolean(primaryRunOrchestrationId)
      ),
    [slide]
  );
  const sortedHeatmapIds = sortBy(map(allSecondaryHeatmaps, 'id'));
  const sortedHeatmapSettings = map(sortedHeatmapIds, (heatmapId) => ({
    heatmapId,
    heatmapSettings: heatmapsSettings?.[heatmapId]?.value,
  }));

  const visibleSecondaryAnalysisOrchestrationIds = useMemo(() => {
    const visibleSecondaryHeatmapIds = map(
      filter(sortedHeatmapSettings, ({ heatmapSettings }) => heatmapSettings?.show && heatmapSettings?.selected),
      'heatmapId'
    );
    const visibleSecondaryHeatmaps = filter(allSecondaryHeatmaps, ({ id }) => includes(visibleSecondaryHeatmapIds, id));

    return uniq(map(visibleSecondaryHeatmaps, 'orchestrationId'));
  }, [slide, JSON.stringify(sortedHeatmapSettings)]);

  const jobs = useJobs({
    enabled: !isEmpty(visibleSecondaryAnalysisOrchestrationIds) && Boolean(slide?.studyId),
    additionalFilters: { orchestrationIds: visibleSecondaryAnalysisOrchestrationIds, studyId: slide?.studyId },
    fullData: true,
  });

  const secondaryAnalysisPolygons = useMemo(() => {
    const polygonsFromJobs = compact(
      flatMap(jobs.data?.jobs, (job: CalculateFeaturesJob) =>
        flatMap(values(job?.params?.secondaryAnalysisPolygons), (caseSlidePolygons) => values(caseSlidePolygons))
      )
    );
    return {
      inclusionMultipolygon: compact(map(polygonsFromJobs, 'inclusionMultipolygon')) as MultiPolygon[],
      exclusionMultipolygon: compact(map(polygonsFromJobs, 'exclusionMultipolygon')) as MultiPolygon[],
    };
  }, [jobs.data?.jobs]);

  return useMemo(() => {
    const idPrefix = join(visibleSecondaryAnalysisOrchestrationIds, '+');
    const inclusionGeojson = reduce(
      secondaryAnalysisPolygons.inclusionMultipolygon,
      (acc, inclusionMultipolygon) => {
        return {
          type: 'FeatureCollection',
          features: concat(acc.features, {
            type: 'Feature',
            geometry: fixMultiPolygon(inclusionMultipolygon) as Geometry,
          }),
        };
      },
      { type: 'FeatureCollection', features: [] }
    );
    const inclusionGeojsonLayers = map(
      inclusionGeojson?.features,
      (feature, index) =>
        new GeoJsonLayer({
          id: `${idPrefix}-inclusionGeojsonLayer-${index}`,
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          data: feature,
          pickable: false,
          stroked: true,
          filled: false,
          getLineColor: [0, 255, 0],
          getLineWidth: 200,
          opacity: 0.5,
          lineWidthMinPixels: 1,
          lineWidthMaxPixels: 5,
          lineWidthScale: rescaleFactor ? 1 / rescaleFactor : 1,
        })
    );

    const exclusionGeojson = reduce(
      secondaryAnalysisPolygons.exclusionMultipolygon,
      (acc, exclusionMultipolygon) => {
        return {
          type: 'FeatureCollection',
          features: concat(acc.features, {
            type: 'Feature',
            geometry: fixMultiPolygon(exclusionMultipolygon) as Geometry,
          }),
        };
      },
      { type: 'FeatureCollection', features: [] }
    );
    const exclusionGeojsonLayers = map(
      exclusionGeojson?.features,
      (feature, index) =>
        new GeoJsonLayer({
          id: `${idPrefix}-exclusionGeojsonLayer-${index}`,
          coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
          data: feature,
          pickable: false,
          stroked: true,
          filled: false,
          getLineColor: [255, 0, 0],
          getLineWidth: 200,
          opacity: 0.5,
          lineWidthMinPixels: 1,
          lineWidthMaxPixels: 5,
          lineWidthScale: rescaleFactor ? 1 / rescaleFactor : 1,
        })
    );

    return compact(concat<GeoJsonLayer>(inclusionGeojsonLayers, exclusionGeojsonLayers));
  }, [secondaryAnalysisPolygons, visibleSecondaryAnalysisOrchestrationIds]);
};
