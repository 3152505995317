import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';

import { Grid, IconButton } from '@mui/material';
import AutomaticConditionGroup from 'components/atoms/ConditionBuilder/AutomaticConditionGroup';
import { validateCondition } from 'components/atoms/ConditionBuilder/utils';
import { AutomaticCondition, CategoricalActions, ConditionType } from 'interfaces/automaticCondition';
import { MappingFilterMetadata } from 'interfaces/postProcessingAction';
import { isEmpty } from 'lodash';
import React from 'react';

export interface NewCellProps {
  cellName: string;
  cellValue: AutomaticCondition;
  mappingFiltersMetadata: MappingFilterMetadata[];
  removeNewCell: () => void;
  updateNewCell: (newCellName: string, newCellValue: AutomaticCondition) => void;
}

const NewCell: React.FC<React.PropsWithChildren<NewCellProps>> = ({
  cellName,
  cellValue,
  mappingFiltersMetadata,
  removeNewCell,
  updateNewCell,
}) => {
  const [newCell, setNewCell] = React.useState({ name: cellName, value: cellValue });
  const [onEdit, setOnEdit] = React.useState(
    isEmpty(newCell.value) || !validateCondition(newCell.value, mappingFiltersMetadata, [CategoricalActions.IS])[0]
  );
  if (onEdit) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={10}>
          <AutomaticConditionGroup
            conditionOptions={[ConditionType.LOGICAL, ConditionType.CELL]}
            editable
            onOperandsChange={(newOperands) => {
              const newValue = isEmpty(newOperands) ? null : newOperands[0];
              setNewCell({ name: newCell.name, value: newValue });
            }}
            operands={newCell.value ? [newCell.value] : []}
            singleOperand
            logicalConditionRecursivelyNumLevels={1}
            mappingFiltersMetadata={mappingFiltersMetadata}
          />
        </Grid>
        <Grid item>
          <IconButton
            disabled={!validateCondition(newCell.value, mappingFiltersMetadata, [CategoricalActions.IS])[0]}
            onClick={() => {
              updateNewCell(newCell.name, newCell.value);
              setOnEdit(false);
            }}
            size="small"
          >
            <DoneIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              setNewCell({ name: cellName, value: cellValue });
              setOnEdit(false);
            }}
            size="small"
          >
            <UndoIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={10}>
        {cellName}
      </Grid>
      <Grid item>
        <IconButton onClick={() => setOnEdit(true)} size="small">
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() => removeNewCell()} size="small">
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default NewCell;
